<script setup>
import { computed } from 'vue'

const props = defineProps({
  tipSelected: [Number, String],
  status: String
})

const imageLevel = computed(() => {
  switch(props.status) {
    case "noDonation":
      return 1
    case "lowDonation":
      return 2
    case "midDonation":
      return 3
    case "highDonation":
      return 4
    default:
      return 2
  }
})

const imageSrc = computed(() => `https://cdn.helloasso.com/images/forms/abtest/abctest_modal_level${imageLevel.value}.svg`)

const show1 = computed(() => imageLevel.value === 1)
const show2 = computed(() => imageLevel.value === 2)
const show3 = computed(() => imageLevel.value === 3)
const show4 = computed(() => imageLevel.value === 4)

</script>

<template>
  <!-- eslint-disable max-len -->
  <div class="voluntary-contribution-illustration">
    <transition name="fade" mode="out-in">
      <img v-if="show1" :src='imageSrc' alt=""/>
    </transition>
    <transition name="fade" mode="out-in">
      <img v-if="show2" :src='imageSrc' alt=""/>
    </transition>
    <transition name="fade" mode="out-in">
      <img v-if="show3" :src='imageSrc' alt=""/>
    </transition>
    <transition name="fade" mode="out-in">
      <img v-if="show4" :src='imageSrc' alt=""/>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.voluntary-contribution-illustration {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    /* stylelint-disable-next-line */
    max-height: 169px;
    @include mediaQuery(900) {
      /* stylelint-disable-next-line */
      width: 240px;
    }
  }


  .fade-enter-active,
  .fade-leave-active {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transition: opacity 0.25s ease;
  }

  .fade-leave {
    position:absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    opacity: 0;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &__no-donation {
    margin: 0 0 $ha-spacing-large 0;
    padding: $ha-unit $ha-unit * 2;
    text-align: center;
    background: var(--ha-color-background);
  }

  &__group {
    opacity: 0;
    transition: all 0.25s ease;

    &--visible {
      opacity: 1;
      transition: all 0.25s ease;
    }
  }

  &__heart {
    transition: 0.25s ease;
    d: var(--heart-path);
  }
}
</style>
