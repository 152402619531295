import { computed, ref } from 'vue'
import useStoreData from '@/composables/useStoreData'
import { useContext } from '@nuxtjs/composition-api'

enum Variation {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

export default () => {

  const isCampaignExecuted = ref(false)
  const isVariationB = computed(() => variation.value === Variation.B)
  const isVariationC = computed(() => variation.value === Variation.C)
  const isVariationD = computed(() => variation.value === Variation.D)
  const isVariationE = computed(() => variation.value === Variation.E)
  const variation = ref(Variation.A)
  const variationId = ref(null)
  const campaignId = ref(null)
  const isOriginal = computed(() => variation.value === Variation.A)

  
  // @ts-expect-error abTasty is a custom plugin
  const { $abTasty } = useContext()

  const initAbTasty = (campaignName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $abTasty.addCallback($abTasty.campaigns[campaignName].id, (detail: any) => {
      if (detail.campaignId === $abTasty.campaigns[campaignName].id) {
        isCampaignExecuted.value = true
        campaignId.value = detail.campaignId
        variationId.value = detail.variationId
      }
      if (detail.variationId === $abTasty.campaigns[campaignName].variations.a) {
        variation.value = Variation.A
      }
      if (detail.variationId === $abTasty.campaigns[campaignName].variations.b) {
        variation.value = Variation.B
      }
      if (detail.variationId === $abTasty.campaigns[campaignName].variations.c) {
        variation.value = Variation.C
      }
      if (detail.variationId === $abTasty.campaigns[campaignName].variations.d) {
        variation.value = Variation.D
      }
      if ($abTasty.campaigns[campaignName].variations.e && detail.variationId === $abTasty.campaigns[campaignName].variations.e) {
        variation.value = Variation.E
      }
    })
  }

  // TODO clean below after abtest is archived (GP-300)
  const { organization } = useStoreData()



  // TODO clean above after abtest is archived (GP-300)

  return {
    initAbTasty,
    isCampaignExecuted,
    isVariationB,
    isVariationC,
    isVariationD,
    isVariationE,
    variation,
    isOriginal,
    campaignId,
    variationId
  }
}
