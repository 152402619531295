<template>
  <div class="NavSteps">
    <NavStep
      v-for="(form, step) in steps"
      :key="step"
      :is-current="!!isCurrent(step + 1)"
      :is-completed="isCompleted(step + 1)"
      :is-disabled="isDisabled(step + 1)"
      :title="getTitleLabel(form.title)"
      :icon="form.icon"
      :width="getChildWidth"
      :data-ux="`Forms_${metadata.type}_NavSteps_Step_${step + 1}`"
      :data-testid="form['data-testid']"
      @click.native="setCurrentStep(step + 1)"
    />
  </div>
</template>

<script>
/* eslint no-console: "off" */
import { size } from 'lodash-es'
import NavStep from '@/components/globals/stepper/NavStep.vue'

export default {
  name: 'NavSteps',
  components: {
    NavStep
  },
  props: {
    steps: Array,
    currentStep: Number
  },
  computed: {
    getChildWidth() {
      return 100 / size(this.steps)
    }
  },
  methods: {
    getTitleLabel(title) {
      return this.$t(title)
    },
    isCurrent(step) {
      return step == this.currentStep
    },
    isCompleted(step) {
      return step < this.currentStep
    },
    isDisabled(step) {
      return step > this.currentStep
    },
    setCurrentStep(step) {
      if (step < this.currentStep) {
        this.$emit('change', step)
      }
    }
  }
}
</script>

<style lang="scss">
.NavSteps {
  display: flex;
  width: 100%;
  overflow: hidden;
  background-clip: padding-box;
  border-radius: $ha-radius-large $ha-radius-large 0 0;
}
</style>
