// @ts-ignore
import { get, min, max, sumBy, has } from 'lodash-es'
import { functions, enum as enums } from '@ha/helpers'
import { Organization } from '@/components/organizations/organizations.interface'
import { Form } from '@/components/forms/forms.interface'
import { Tier } from '@/components/tiers/tiers.interface'

export const getSeoEventType = (eventType: string): string => {
  // Input: "Cour(s) / Leçon(s) / Entrainement(s)"
  // Output: "Cour"
  if (!eventType) return ''
  const cleanEventType = eventType.split('/')[0]
  return cleanEventType.replace('(s)', '').trim()
}

export const getSeoDescription = (
  city: string,
  eventType: string,
  organizationName: string,
  organizationType: string,
  formDescription: string,
  formType: string,
  i18n: { t: (key: string, params?: any[]) => string; tc: (key: any, params: any) => string },
  publicName: string
): string => {
  const description = formDescription ? formDescription.replace(/<[^>]*>?/gm, '') : ''
  let firstPart = description
  const isShortDescription = description.length < 60
  const isShortEventType = eventType.length < 15

  switch (formType) {
    case enums.FormType.EVENT:
      if (city) {
        return i18n.t(`metaTags.event.descriptionWithCity`, [
          i18n.t(`metaTags.event.title.${eventType}`),
          organizationName,
          city,
          description
        ])
      }
      return i18n.t(`metaTags.event.descriptionWithoutCity`, [
        i18n.t(`metaTags.event.title.${eventType}`),
        organizationName,
        description
      ])
    case enums.FormType.MEMBERSHIP:
      if (city) {
        return i18n.t(`metaTags.membership.descriptionWithCity`, [
          i18n.t(`metaTags.membership.title.${eventType}`),
          organizationName,
          city,
          description
        ])
      }
      return i18n.t(`metaTags.membership.descriptionWithoutCity`, [
        i18n.t(`metaTags.membership.title.${eventType}`),
        organizationName,
        description
      ])
    case enums.FormType.SHOP:
      if (city) {
        return i18n.t(`metaTags.shop.descriptionWithCity`, [
          i18n.t('metaTags.shop.title'),
          organizationName,
          city,
          description
        ])
      }
      return i18n.t(`metaTags.shop.descriptionWithoutCity`, [
        i18n.t('metaTags.shop.title'),
        organizationName,
        description
      ])
    case enums.FormType.CROWDFUNDING:
      if (city) {
        return i18n.t(`metaTags.crowdfunding.descriptionMasculinWithCity`, [
          i18n.t('metaTags.crowdfunding.title'),
          organizationName,
          city,
          description
        ])
      }
      return i18n.t(`metaTags.crowdfunding.descriptionMasculinWithoutCity`, [
        i18n.t('metaTags.crowdfunding.title'),
        organizationName,
        description
      ])
    case enums.FormType.DONATION:
      return i18n.t(`metaTags.donation.description`, [organizationName, publicName])
    default:
      // Form description is input through a wysiwyg editor and will contains html tags, remove them.
      if (organizationType === 'FondDotation') {
        organizationType = i18n.t('organismType.endowmentFund')
      } else if (organizationType.toLowerCase().includes('foundation'.toLowerCase())) {
        organizationType = i18n.t('organismType.foundation')
      } else {
        organizationType = i18n.t('organismType.association')
      }

      if (isShortDescription) {
        if (city && organizationType) {
          if (isShortEventType) {
            firstPart = i18n.t(`metaTags.event.description.descFirst`, [
              description,
              organizationType,
              organizationName,
              eventType.toLowerCase(),
              city
            ])
          } else {
            firstPart = i18n.t(`metaTags.event.description.descLast`, [
              organizationType,
              organizationName,
              eventType.toLowerCase(),
              city,
              description
            ])
          }
        }
      } else if (city && organizationType) {
        // return here, don't add suffix
        return i18n.t(`metaTags.event.description.descFirst`, [
          description,
          organizationType,
          organizationName,
          eventType.toLowerCase(),
          city
        ])
      }
      return i18n.t(`metaTags.event.description.suffix`, [firstPart])
  }
}

export const getMicroData = (form: Form, organization: Organization) => {
  const graph = []
  graph.push(getOrganizationMicrodata(form, organization))
  if (form.formType === 'Event') graph.push(getEventMicrodata(form, organization))

  return {
    '@context': 'http://schema.org',
    '@graph': graph
  }
}

const getOrganizationMicrodata = (form: Form, organization: Organization) => {
  const socials = []
  if (get(organization, 'facebookPage')) {
    socials.push(get(organization, 'facebookPage'))
  }
  if (get(organization, 'twitterPage')) {
    socials.push(get(organization, 'twitterPage'))
  }
  if (get(organization, 'youtubePage')) {
    socials.push(get(organization, 'youtubePage'))
  }
  if (get(organization, 'instagramPage')) {
    socials.push(get(organization, 'instagramPage'))
  }

  switch (form.formType) {
    case enums.FormType.MEMBERSHIP:
      return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: `${process.env.NUXT_ENV_BASE_URL}/associations/${get(
          organization,
          'organizationSlug'
        )}`,
        logo:
          get(organization, 'banner') ||
          get(organization, 'logo') ||
          `${process.env.NUXT_ENV_BASE_URL}/forms/helloasso-logo.png`,
        name: 'HelloAsso',
        sameAs: socials
      }

    case enums.FormType.EVENT:
    default:
      return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: 'https://www.helloasso.com',
        logo: `${process.env.NUXT_ENV_BASE_URL}/forms/helloasso-logo.png`,
        name: 'HelloAsso',
        sameAs: [
          'https://www.facebook.com/helloasso',
          'https://twitter.com/helloasso',
          'https://www.youtube.com/channel/UCYz5xk40Yayisba7qTjHAdQ'
        ]
      }
  }
}

const getEventMicrodata = (form: Form, organization: Organization) => {
  const urlAsso = form?.url?.split('/evenements', 1)?.[0] || ''
  const vignetteUrl = functions.getFormVignette(
    form,
    {
      width: 500,
      height: 360
    },
    'fit'
  )

  return {
    '@type': 'Event',
    id: form?.url,
    url: form?.url,
    logo: vignetteUrl,
    description: form.description,
    name: form?.title,
    organizer: {
      '@type': 'Organization',
      name: organization?.name,
      url: urlAsso
    },
    offers: getOffersMicrodata(form),
    startDate: form?.startDate,
    endDate: form?.endDate,
    image: form?.imageDesc,
    location: getPlaceMicrodata(form)
  }
}

const inStock = ({ remainingNumber }: Tier) => {
  return remainingNumber !== undefined && remainingNumber > 0
    ? 'http://schema.org/InStock'
    : 'http://schema.org/SoldOut'
}

const getOffersMicrodata = (form: Form) => {
  const prices = form.tiers.map(tier => functions.convertToEuros(tier.price))

  //! Refactor - does it even works ?
  // Had to use "any" since the return type of the call back hasn't any doesn't match sumBy requirements
  const quantity = sumBy(prices, function (o: any) {
    return o !== undefined
  } as any)

  return {
    '@type': 'AggregateOffer',
    url: get(form, 'url'),
    highPrice: quantity > 1 ? max(prices) : undefined,
    lowPrice: quantity > 1 ? min(prices) : undefined,
    price: quantity === 1 ? prices[0] : min(prices),
    priceCurrency: 'Eur',
    offerCount: get(form, 'remainingEntries'),
    availabilityStarts: get(form, 'saleStartDate'),
    availabilityEnds: get(form, 'saleEndDate'),
    availability:
      form.remainingEntries === undefined ? undefined : inStock(form as unknown as Tier), //! Refactor - does it even works ?
    validFrom: get(form, 'saleStartDate'),
    offers: form.tiers.map(tier => ({
      url: get(form, 'url'),
      name: get(tier, 'label'),
      price:
        get(tier, 'price') === undefined ? undefined : functions.convertToEuros(get(tier, 'price')),
      priceCurrency: 'Eur',
      availabilityStarts: get(tier, 'saleStartDate'),
      availabilityEnds: get(tier, 'saleEndDate'),
      validFrom: get(tier, 'saleStartDate'),
      availability: tier.remainingNumber === undefined ? undefined : inStock(tier)
    }))
  }
}

const getPlaceMicrodata = (form: Form) => {
  if (has(form, 'place.city') && has(form, 'place.country')) {
    return {
      '@type': 'Place',
      name: form.place.name,
      address: {
        '@type': 'PostalAddress',
        streetAddress: form.place.address,
        addressLocality: form.place.city,
        postalCode: form.place.zipCode,
        addressCountry: form.place.country
      }
    }
  }
}
