var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'NavStep',
    {
      'NavStep-Current': _vm.isCurrent,
      'NavStep-Completed': _vm.isCompleted,
      'NavStep-a11y': _vm.isQuickWinForm
    }
  ],style:(_vm.getStyles),attrs:{"type":"button","disabled":_vm.isDisabled,"tabindex":_vm.isCurrent || _vm.isDisabled ? -1 : 0}},[_c('fa',{attrs:{"icon":_vm.icon,"aria-hidden":"true"}}),_vm._v(" "),(_vm.isCompleted || _vm.isCurrent)?_c('span',{staticClass:"visually-hidden"},[_vm._v("\n    "+_vm._s(_vm.isCurrent ? 'Actuelle :' : _vm.isCompleted ? 'Complétée :' : '')+"\n  ")]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.title))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }