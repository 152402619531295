<script setup>
import { functions } from '@ha/helpers'
import { computed, ref, nextTick, watch } from 'vue'
import { HaButton, HaInput } from '@ha/components'
import useTipEdition from '@/components/voluntary-contributions/partials/TipSelectors/useTipEdition'

const props = defineProps({
  tip: { type: [String, Number], required: true },
  tipSuggestions: { type: Object, required: true }
})

const emit = defineEmits(['update:tip', 'custom-field:blur'])

const {
  updateTip,
  orderedTipSuggestions,
  lastStoredValue,
  updateLastStoredValue,
  dataUxInput,
  isUsingCustomField
} = useTipEdition(props, emit)

let preventCustomFieldAutoFill = false // prevent autofilling an empty customField while clicking on a tip suggestion btn
const isCustomFieldFocused = ref(false)

const buttonStates = computed(() => {
  const isBtnActive = tipValue =>
    !isUsingCustomField.value &&
    !isCustomFieldFocused.value &&
    parseFloat(tipValue) === parseFloat(props.tip)
  return orderedTipSuggestions.value.map(tipValue => ({
    tipValue,
    variant: isBtnActive(tipValue) ? 'fill' : 'flat',
    class: [
      'button-list__item',
      {
        'button-list__item--selected': isBtnActive(tipValue)
      }
    ]
  }))
})

const setIsUsingCustomField = () => {
  isUsingCustomField.value =
    isCustomFieldFocused.value ||
    orderedTipSuggestions.value.every(
      suggestion => parseFloat(suggestion) !== parseFloat(props.tip)
    )
}

const onClick = async tipValue => {
  isUsingCustomField.value = false
  preventCustomFieldAutoFill = false
  updateTip(tipValue)

  await nextTick()
  updateLastStoredValue()
}
const onMousedown = () => {
  preventCustomFieldAutoFill = true
}

const onCustomValueInput = value => {
  isUsingCustomField.value = !!value
  updateTip(value)
}
const onCustomFieldFocus = () => {
  isCustomFieldFocused.value = true
}
const onCustomFieldBlur = async () => {
  isCustomFieldFocused.value = false

  if (isUsingCustomField.value) {
    updateLastStoredValue()
  } else if (!preventCustomFieldAutoFill) {
    updateTip(lastStoredValue.value)

    await nextTick()
    setIsUsingCustomField()
  }

  emit('custom-field:blur')
}

watch(props, ({ tip }) => setIsUsingCustomField(tip))

setIsUsingCustomField()
</script>

<template>
  <div class="button-list">
    <HaButton
      v-for="(optionState, index) in buttonStates"
      :key="index"
      :variant="optionState.variant"
      :class="optionState.class"
      @mousedown="onMousedown"
      @click="onClick(optionState.tipValue)"
    >
      {{ optionState.tipValue }}
    </HaButton>
    <div class="button-list__item button-list__custom-field">
      <HaInput
        :value="isUsingCustomField ? tip : ''"
        :class="[
          'button-list__custom-field__input',
          {
            'button-list__custom-field__input--selected':
              !isCustomFieldFocused && isUsingCustomField
          }
        ]"
        :placeholder="functions.capitalizeFirstLetter($t('globals.other'))"
        :data-ux="dataUxInput"
        size="small"
        step="0.01"
        @focus="onCustomFieldFocus"
        @blur="onCustomFieldBlur"
        @input="onCustomValueInput"
        @keydown="functions.allowOnlyNumericEntry($event, { allowDecimal: true })"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.button-list {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    width: $ha-unit * 8;
    padding: 0;
    border-color: var(--ha-color-text-lightest);
    border-style: solid;
    border-width: $ha-border-width-regular 0 $ha-border-width-regular $ha-border-width-regular;
    border-radius: 0;

    &::after {
      margin-left: $ha-spacing-mini;
      content: '€';
    }

    &:is(button):hover {
      color: white;
      font-weight: $ha-font-weight-bold;
      background-color: var(--ha-color-primary);
      border-color: var(--ha-color-primary);
    }

    &:first-child {
      border-radius: $ha-radius-regular 0 0 $ha-radius-regular;
    }

    &:last-child {
      border-right-width: 1px;
      border-radius: 0 $ha-radius-regular $ha-radius-regular 0;
    }

    &--selected {
      font-weight: $ha-font-weight-bold;
    }
  }

  &__custom-field {
    width: $ha-unit * 10;
    padding: $ha-spacing-tiny;

    &__input {
      :deep(.Input) {
        padding: 0;
        text-align: center;
      }

      &--selected:deep(.Input) {
        color: white;
        font-weight: $ha-font-weight-bold;
        background-color: var(--ha-color-primary);
      }
    }
  }
}
</style>
