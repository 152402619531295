import { getTier } from '@/components/tiers/helpers/index'
import { SelectedTiers, Tier } from '@/components/tiers/tiers.interface'
import { CartItem, Cart, CartItemAltered } from '@/components/carts/carts.interface'

const getCartItem = (tierId: string, index: number, cart?: Cart): CartItem | undefined => {
  if (!tierId || index == null) throw new Error('Missing required params tierId or index')
  if (!cart?.itemList) return undefined

  /* Optional donation is a cart's item without tierId, so we need to use optional chaining to prevent error */
  return cart.itemList.filter(item => item.tierId?.toString() === tierId && item)?.[index]
}

export const getRequiredCartItemData = (tier: Tier): CartItem => {
  if (!tier) throw new Error('Missing required params selectedTiers or tier ')

  /* We need to send paymentFrequency, price, isEligibleTaxReceipt and vatRate
  because server checks that the tier has not been modified in the meantime */
  return {
    tierId: tier.id as number,
    tierType: tier.tierType as string,
    paymentFrequency: tier.paymentFrequency,
    price: tier.price,
    isEligibleTaxReceipt: tier.isEligibleTaxReceipt,
    vatRate: tier.vatRate
  }
}

const formatToCartItems = (
  selectedTiers: SelectedTiers,
  tier: Tier,
  cartItem?: CartItem
): CartItem => {
  if (!selectedTiers || !tier) throw new Error('Missing required params selectedTiers or tier ')

  const requiredTierData = getRequiredCartItemData(tier)

  return {
    ...requiredTierData,
    customAmount: selectedTiers[tier.id as number].customAmount,
    customFields: cartItem?.customFields ?? [],
    extraOptions: cartItem?.extraOptions ?? [],
    firstname: cartItem?.firstname,
    lastname: cartItem?.lastname,
    email: cartItem?.email
  }
}

export const formatSelectedTierToCartItems = (
  selectedTiers: SelectedTiers,
  tiers: Tier[],
  cart?: Cart
): (CartItem | CartItemAltered)[] => {
  if (!selectedTiers || !tiers) throw new Error('Missing required params selectedTiers or tiers')

  const tierIds = Object.keys(selectedTiers)

  return tierIds.reduce((cartItems: CartItem[], tierId: string): CartItem[] => {
    const tier = getTier(tiers, tierId)
    if (!tier) throw new Error("Can't retrieve tier from selectedTiers")
    const cartItemsFromTier = []
    for (let index = 0; index < selectedTiers[tierId].quantity; index++) {
      const cartItem = getCartItem(tierId, index, cart)
      cartItemsFromTier.push(formatToCartItems(selectedTiers, tier, cartItem))
    }
    return [...cartItems, ...cartItemsFromTier]
  }, [] as CartItem[])
}

const formatToSelectedTier = (cart: Cart, tierId: string) => {
  if (!cart || !tierId) throw new Error('Missing required params cart or tierId')
  const cartItems = cart.itemList.filter(item => item.tierId?.toString() === tierId) || []
  return { customAmount: cartItems[0]?.customAmount, quantity: cartItems.length }
}

export const formatCartItemsToSelectedTiers = (cart?: Cart): SelectedTiers => {
  if (!cart?.itemList?.length) return {}

  const tierIds = Array.from(new Set(cart.itemList.map(item => item.tierId?.toString()))).filter(
    item => item
  )

  return tierIds.reduce((selectedTiers: SelectedTiers, tierId: string) => {
    return {
      ...selectedTiers,
      [tierId]: formatToSelectedTier(cart, tierId)
    }
  }, {})
}
