<template>
  <ha-modal class="ModalContributionExplication" v-on="$listeners" data-testid="cv-reason-modal">
    <template #header data-testid="title-reason-modal">
      <span data-testid="title-reason-modal">
        {{ $t('modal.contributionExplication.title') }}
      </span>
    </template>
    <template #body>
      <div data-testid="content-reason-modal">
        <p>
          {{ $t('modal.contributionExplication.intro_1') }}<br />
          {{ $t('modal.contributionExplication.intro_2') }}
        </p>
        <ul>
          <li>{{ $t('modal.contributionExplication.content_1') }}</li>
          <li>{{ $t('modal.contributionExplication.content_2') }}</li>
          <li>{{ $t('modal.contributionExplication.content_3') }}</li>
        </ul>
        <p>
          <strong>{{ $t('modal.contributionExplication.conclusion') }}</strong>
        </p>
      </div>
    </template>
    <template #footer>
      <ha-button
        :data-ux="`Forms_${metadata.type}${
          isWidget ? 'Widget' : ''
        }_Modal_ContributionExplication_GotIt`"
        data-testid="button-close"
        @click="$emit('close')"
      >
        {{ $t('modal.contributionExplication.cta') }}
      </ha-button>
    </template>
  </ha-modal>
</template>

<script>
import { HaModal, HaButton } from '@ha/components'

export default {
  name: 'ModalContributionExplication',
  inject: ['isWidget'],
  components: { HaModal, HaButton }
}
</script>

<style lang="scss">
.ModalContributionExplication {
  font-size: $ha-font-size-small;
  text-align: left;

  ul {
    padding-left: $ha-spacing-big;
    list-style: disc;

    li {
      margin-bottom: $ha-spacing-medium;
      color: var(--ha-color-text-light);
    }

    + p {
      color: var(--ha-color-text);
    }
  }
}
</style>
