<script setup>
import { HaFormField, HaInputGroup, HaInput } from '@ha/components'
import { ValidationProvider } from 'vee-validate'
import { computed, useSlots } from 'vue'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'
import { cleanInputStringNumber } from '@/helpers/formUtils'
import { functions } from '@ha/helpers'

const { form, organization } = useStoreData()
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods
const trackingData = computed(() => {
  return {
    form_id: form.value.formId,
    form_slug: form.value.formSlug,
    form_type: form.value.formType,
    step: 0,
    step_name: 'Montant',
    organization_slug: organization.value.organizationSlug
  }
})

const slots = useSlots()
const emits = defineEmits(['input'])

const props = defineProps({
  isImmediate: {
    type: Boolean,
    default: false
  },
  rules: {
    type: String,
    required: true
  },
  size: {
    type: String,
    validator: value => ['small', 'medium', 'large'].includes(value),
    default: 'medium'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  value: {
    type: [String, Number],
    default: null
  },
  dataTest: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: '--.--'
  },
  min: {
    type: Number,
    default: null
  },
  step: {
    type: String,
    default: null
  },
  trackingName: {
    type: String,
    required: false
  }
})
const hasSuffix = computed(() => !!slots.suffix)
const hasLabel = computed(() => !!slots.customPriceLabel)
const customPriceSizeClass = computed(() => `custom-price-${props?.size}`)
const customPriceUnitSizeClass = computed(() => `custom-price-unit-${props?.size}`)

const handleInput = value => {
  emits('input', cleanInputStringNumber(value))
}

const handleKeyDown = event => {
  functions.allowOnlyNumericEntry(event, { allowDecimal: true })
}

const handleClick = () => {
  if (!props.trackingName) return
  onFirstClick(props.trackingName, trackingData.value)
}
</script>

<template>
  <ValidationProvider
    v-slot="{ errors, valid, validated, failedRules }"
    ref="custom-price"
    :immediate="isImmediate"
    :rules="rules"
  >
    <HaFormField
      :is-valid="validated ? valid : null"
      class="custom-price__field"
      :class="customPriceSizeClass"
    >
      <template v-if="hasLabel" #label>
        <slot name="customPriceLabel"></slot>
      </template>
      <HaInputGroup class="custom-price__field__group">
        <HaInput
          id="custom-price-input"
          :value="value"
          type="text"
          class="custom-price__field__group__input"
          :placeholder="placeholder"
          :data-test="dataTest"
          :min="min"
          :step="step"
          :disabled="disabled"
          :is-valid="validated && rules.length > 0 ? valid : null"
          @input="handleInput"
          @click="handleClick"
          @keydown="handleKeyDown"
        />
        <div
          v-if="hasSuffix"
          class="custom-price__field__group__unit"
          :class="customPriceUnitSizeClass"
        >
          <slot name="suffix"></slot>
        </div>
      </HaInputGroup>
      <template #error>
        <template v-if="failedRules.required">
          {{ $t('forms.requiredField') }}
        </template>
        <template v-else>
          {{ errors[0] }}
        </template>
      </template>
    </HaFormField>
  </ValidationProvider>
</template>

<style scoped lang="scss">
$ha-custom-price-small: $ha-unit * 5;
$ha-custom-price-medium: $ha-unit * 8;
$ha-custom-price-large: $ha-unit * 10;
$ha-custom-price-font-size-small: $ha-unit * 3;
$ha-custom-price-font-size-medium: $ha-unit * 4;
$ha-custom-price-font-size-large: $ha-unit * 5;

.custom-price {
  max-height: 100%;

  &-small {
    font-size: $ha-custom-price-font-size-small;

    :deep(.Input--Wrapper .Input) {
      text-align: left;
    }

    :deep(.Input--Wrapper .Input::placeholder) {
      text-align: center;
    }

    :deep(.Input--Wrapper .Input--Valid) {
      padding-right: $ha-unit;
      background-position: center right $ha-unit;
    }

    &.custom-price__field__group__unit {
      width: $ha-unit * 4;
      font-size: $ha-custom-price-font-size-small;
    }
  }

  &-medium {
    .custom-price__field__group {
      height: $ha-custom-price-medium;
    }

    :deep(.Input--Wrapper .Input) {
      font-size: $ha-custom-price-font-size-medium;
    }

    .custom-price__field__group__unit {
      font-size: $ha-custom-price-font-size-medium;
    }
  }

  &-large {
    .custom-price__field__group {
      height: $ha-custom-price-large;
    }

    :deep(.Input--Wrapper .Input) {
      font-size: $ha-custom-price-font-size-large;
    }

    .custom-price__field__group__unit {
      font-size: $ha-custom-price-font-size-large;
    }
  }

  &__field {
    .HaError {
      margin-top: $ha-spacing-small;
      text-align: center;
    }

    &__group {
      justify-content: center;

      :deep(.Input--Wrapper .Input) {
        text-align: center;
      }

      &__input {
        max-width: $ha-unit * 25;

        .Input {
          font-weight: $ha-weight-semibold;
          text-align: center;
          border: $ha-border-regular;
          border-radius: $ha-radius-large;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &__unit {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: $ha-unit * 10;
        border: $ha-border-regular;
        border-radius: $ha-radius-large;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        p {
          margin: 0;
          color: var(--ha-color-text);
          font-weight: $ha-weight-semibold;
        }
      }
    }
  }
}
</style>
