<script setup>
import { inject, computed, ref, watch } from 'vue'
import { functions } from '@ha/helpers'
import { HaModal, HaButton, HaCheckbox, HaFormField } from '@ha/components'
import { cleanInputStringNumber } from '@/helpers/formUtils'
import useRouteParams from '@/composables/useRouteParams'
import useStoreData from '@/composables/useStoreData'
import AnimatedIllustration from '@/components/voluntary-contributions/partials/AnimatedIllustration.vue'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import RangeSelector from '@/components/voluntary-contributions/partials/TipSelectors/RangeSelector.vue'
import ButtonGroup from '@/components/voluntary-contributions/partials/TipSelectors/ButtonGroup.vue'
import useFormType from '@/composables/useFormType'
import useTracking from '@/composables/useTracking'
import useModalities from '@/composables/useModalities'

const emit = defineEmits(['close'])
const props = defineProps({
  isCheckout: Boolean,
  tiersAmount: Number,
  paymentInstallmentsNumber: [Number, String]
})
const isWidget = inject('isWidget')
const { formType } = useRouteParams()
const { organization } = useStoreData()
const { isMembership } = useFormType()
const {
  tipSelectedInEuros,
  tipSuggestedInEuros,
  updateTipSelectedInEuros,
  tipSelected,
  tipSuggested
} = useVoluntaryContribution()
const { trackingMethods } = useTracking()
const currentTipSelectedInEuros = ref(
  parseFloat(tipSelectedInEuros.value)
    ? parseFloat(tipSelectedInEuros.value).toFixed(2)
    : tipSuggestedInEuros.value.default
)

const { isPaymentTermsEnabled } = useModalities()

const isTipDisabled = ref(!currentTipSelectedInEuros.value)

const parsedTipSelectedInEuro = computed(() =>
  currentTipSelectedInEuros.value ? parseFloat(currentTipSelectedInEuros.value) : 0
)
const bankTransactionCostInEuros = computed(() => functions.convertToEuros(props.tiersAmount / 100))
const showCatchUp = computed(() => {
  return (
    currentTipSelectedInEuros.value &&
    parsedTipSelectedInEuro.value <= 0 &&
    props.tiersAmount >= 10000
  )
})
const dataUxSaveButton = computed(
  () =>
    `Forms_${formType.value.toUpperCase()}${
      isWidget ? 'Widget' : ''
    }_Modal_ContributionSelection_SaveContribution`
)
const dataUxNoTip = computed(
  () =>
    `Forms_${formType.value.toUpperCase()}${
      isWidget ? 'Widget' : ''
    }_Modal_ContributionSelection_NoContribution`
)

const ABTestingState = computed(() => {
  /* __ContributionLineCV is variable inject by AB Tasty
  @TODO : Remove it after A/B testing's validation */
  const { __ContributionLineCV } = window
  return isMembership.value && __ContributionLineCV === 'modal_buttons'
})

const onToggleIsTipDisabled = () => {
  currentTipSelectedInEuros.value = isTipDisabled.value ? '0' : tipSuggestedInEuros.value.default
}

const tipTracking = eventName => {
  const tipData = {
    tipSuggestion: tipSuggested.value.default,
    tipSuggestionMax: tipSuggested.value.max,
    tipSuggestionMin: tipSuggested.value.min,
    tipChosen: tipSelected.value
  }
  trackingMethods.onTipsUpdate(eventName, tipData, {
    payment_amount: parseFloat(functions.convertToEuros(props.tiersAmount)).toFixed(2),
    payment_frequency_type: props.paymentInstallmentsNumber,
    contribution_default_amount: parseFloat(
      functions.convertToEuros(tipSuggested.value.default)
    ).toFixed(2),
    contribution_amount: parseFloat(functions.convertToEuros(tipSelected.value)).toFixed(2)
  })
}
const onSaveAndUpdate = newTipSelected => {
  updateTipSelectedInEuros(newTipSelected, isPaymentTermsEnabled.value)
  tipTracking('Tips Catch Up')
  emit('close')
}
const onSave = () => {
  updateTipSelectedInEuros(parsedTipSelectedInEuro.value,isPaymentTermsEnabled.value)
  tipTracking('Tips Submitted')
  emit('close')
}
const onTipUpdate = value => {
  currentTipSelectedInEuros.value = value ? cleanInputStringNumber(value) : ''
}

watch(currentTipSelectedInEuros, () => {
  isTipDisabled.value = !!currentTipSelectedInEuros.value && parsedTipSelectedInEuro.value <= 0
})
</script>

<template>
  <HaModal
    class="contribution-modal-modify"
    @close="emit('close')"
    data-testid="contribution-modal"
  >
    <template #header>
      {{ $t(`modal.contributionSelection.${ABTestingState ? 'editMyContribution' : 'title'}`) }}
    </template>
    <template #body>
      <p>
        {{ $t(`modal.contributionSelection.intro_1${isCheckout ? '_checkout' : ''}`) }}
      </p>
      <p v-if="!ABTestingState">
        <strong>{{
          $t(`modal.contributionSelection.intro_2${isCheckout ? '_checkout' : ''}`)
        }}</strong>
      </p>

      <div class="contribution-modal-modify__body">
        <AnimatedIllustration :tip-selected="currentTipSelectedInEuros" />
        <HaFormField class="contribution-modal-modify__field">
          <template #label>
            <span class="contribution-modal-modify__field__label">
              {{ $t('modal.contributionSelection.contributeUpTo') }}
            </span>
          </template>

          <ButtonGroup
            v-if="ABTestingState"
            :tip="currentTipSelectedInEuros"
            :tip-suggestions="{ ...tipSuggestedInEuros, none: '0' }"
            @update:tip="onTipUpdate"
          />
          <RangeSelector
            v-else
            :tip="currentTipSelectedInEuros"
            :tip-suggestions="tipSuggestedInEuros"
            @update:tip="onTipUpdate"
          />
        </HaFormField>

        <HaCheckbox
          v-if="!ABTestingState"
          v-model="isTipDisabled"
          name="dontTip"
          :data-ux="dataUxNoTip"
          class="contribution-modal-modify__field__checkbox"
          data-testid="checkbox-no-contribution"
          @change="onToggleIsTipDisabled"
        >
          {{ $t('modal.contributionSelection.noContribution') }}
        </HaCheckbox>
      </div>
      <div
        v-if="showCatchUp"
        class="contribution-modal-modify__catch-up"
        data-testid="retake-wording"
      >
        <p>
          {{ $t('modal.contributionSelection.catchUpTips.yourSupportTo', [organization.name]) }}
        </p>
        <p>
          {{ $t('modal.contributionSelection.catchUpTips.yourSupportTo2') }}
        </p>
        <p>
          {{ $t('modal.contributionSelection.catchUpTips.yourSupportTo3') }}
        </p>
        <p>
          {{
            $t('modal.contributionSelection.catchUpTips.helpPayBankfee', [
              bankTransactionCostInEuros
            ])
          }}
        </p>
      </div>
    </template>
    <template #footer>
      <template v-if="showCatchUp">
        <HaButton
          fluid
          variant="outline"
          :data-ux="dataUxSaveButton"
          data-testid="button-validate-retake"
          @click="onSaveAndUpdate(0)"
        >
          {{ $t('modal.contributionSelection.catchUpTips.noThankYou') }}
        </HaButton>
        <HaButton
          fluid
          :data-ux="dataUxSaveButton"
          data-testid="button-no-retake"
          @click="onSaveAndUpdate(bankTransactionCostInEuros)"
        >
          {{ $t('modal.contributionSelection.catchUpTips.addTips', [bankTransactionCostInEuros]) }}
        </HaButton>
      </template>
      <HaButton v-else fluid :data-ux="dataUxSaveButton" data-testid="button-save" @click="onSave">
        {{ $t('button.save') }}
      </HaButton>
    </template>
  </HaModal>
</template>

<style lang="scss">
.contribution-modal-modify {
  font-size: $ha-font-size-small;

  &__body {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &__field {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: $ha-spacing-small;

    &__label {
      margin-bottom: $ha-spacing-large;
      color: var(--ha-color-text);
      font-size: $ha-font-size-big;
    }
  }

  &__catch-up {
    margin-top: $ha-spacing-medium;

    p {
      color: var(--ha-color-text);
    }
  }
}
</style>
