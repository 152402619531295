<template>
  <div class="CampaignContent Columns" @click="recordAction" @keydown="recordAction">
    <HeaderCVDropdown v-if="isQuickWinForm" />
    <div class="CampaignForm Column Is-Three-Quarters">
      <!-- ab test component -->
      <FormHeaderTest
        v-if="
          formTypes.PAYMENTFORM !== form.formType && !isWidget && (isVariationC || isVariationE)
        "
        :step="step"
      />
      <FormHeader
        v-if="
          formTypes.PAYMENTFORM !== form.formType && !isWidget && !isVariationC && !isVariationE
        "
        :step="step"
      />
      <!-- ab test component -->
      <VoluntaryContributionCardTest v-if="isFirstStep && (isVariationC || isVariationE)" />
      <VoluntaryContributionCard v-if="isQuickWinForm" class="voluntary-card--mobile" />
      <FormRaisingState v-if="showFullRaisingState" />

      <ha-jumbotron
        v-if="isClosed"
        id="tickets"
        class="CampaignForm--Expiration"
        direction="vertical"
        :title="jumbotronTitle"
        :sub-title="$t('jumbotron.subtitle')"
      >
        <template #callToAction>
          <ha-button
            :href="organismPageUrl"
            target="_blank"
            rel="noopener noreferrer"
            variant="outline"
            size="large"
            icon-side="right"
            :data-ux="`Forms_${metadata.type}_Jumbotron_OrganismPage`"
          >
            {{ $t('jumbotron.toOrganismPage') }}
            <template #icon>
              <fa icon="chevron-right" />
            </template>
          </ha-button>
        </template>
      </ha-jumbotron>

      <FormPresentation
        v-if="isFirstStep && (isDescriptionsInverted || isCrowdfunding)"
        :is-inverted-description="isDescriptionsInverted"
      />

      <template v-if="!isClosed">
        <FormBlockedCollectBanner v-if="!isCollectAllowed" :is-widget="isWidget" />
        <validation-observer v-else v-slot="{ valid }" slim>
          <div :class="['CampaignForm--Wrapper', { FormError: isInvalidForm }]">
            <FormStepper
              v-if="isCollectAllowed"
              id="anchorToTickets"
              :steps="formSteps"
              :current-step="step"
              data-testid="stepper-tabs"
              @change="onStepChange"
            />
            <transition :name="step > 1 ? 'slide-fade' : 'none'" mode="out-in">
              <!-- this is where the current step form is insterted  -->
              <component
                :is="currentStepComponent"
                :go-to-next-step.sync="goToNextStep"
                :can-click-next.sync="canClickNext"
                :total-amount.sync="totalAmount"
                :is-fetching="isFetching"
                @go-next="onNextStep"
              />
            </transition>
            <HaTile
              v-if="
                !isQuickWinForm && isFirstStep && isCollectAllowed && !isVariationC && !isVariationE
              "
              :class="[{ ABTest: isVariationB }]"
            >
              <!-- ab test component -->
              <NoticeVoluntaryContributionTest v-if="isVariationB" horizontal center-text />
              <NoticeVoluntaryContribution
                v-if="!isVariationB && !isVariationC && !isVariationE"
                horizontal
                center-text
              />
            </HaTile>
          </div>
          <!-- the client-only tag prevents error: Cannot read property '$scopedSlots' of undefined -->
          <client-only>
            <AmountControls
              v-if="isQuickWinForm"
              :price="totalAmount"
              :label="priceLabel"
              :isLoading="!!isFetching"
              :isWidget="isWidget"
              :canClickNext="isLastStep ? true : canClickNext"
              :validForm="valid"
              :isFormAdmin="isFormAdmin"
              :buttonLabel="nextButtonText"
              :isFirstStep="isFirstStep"
              @on-button-click="valid ? onNextStep() : onFormInvalid()"
            />
            <div
              v-if="displayControls && !isQuickWinForm"
              :class="[
                { 'CampaignForm--Controls-Fixed': !isWidget },
                { SingleButton: !canDisplayPrevious || !canDisplayNext },
                { FormAdmin: isFormAdmin },
                { 'CampaignForm--Controls-Widget': isWidget }
              ]"
              data-testid="forms-steps"
            >
              <ha-button
                v-if="canDisplayPrevious"
                class="PreviousButton"
                color="basic"
                variant="flat"
                size="large"
                :loading="!!isBacking"
                :disabled="isDisabledPrevious"
                :data-ux="`Forms_${metadata.type}${isWidget ? 'Widget' : ''}_PreviousStep`"
                data-test="button-previous-step"
                @click="onPreviousStep"
              >
                {{ previousButtonText }}
                <template #icon>
                  <fa icon="angle-left" />
                </template>
              </ha-button>
              <ha-button
                v-if="canDisplayNext && isCollectAllowed"
                :class="[
                  'NextButton',
                  { ValidatingButton: (!canClickNext || !valid) && isQuickWinForm }
                ]"
                size="large"
                icon-side="right"
                :disabled="(!valid || isDisabledNext) && !isQuickWinForm"
                :loading="!!isFetching"
                :data-ux="`Forms_${metadata.type}${isWidget ? 'Widget' : ''}_NextStep`"
                data-test="button-next-step"
                @click="valid ? onNextStep() : onFormInvalid()"
              >
                {{ nextButtonText }}
                <template #icon>
                  <fa icon="angle-right" />
                </template>
              </ha-button>
            </div>
          </client-only>
        </validation-observer>
      </template>
      <Reassurance v-if="isQuickWinForm && (isFirstStep || isLastStep)" />
      <template v-if="isFirstStep">
        <FormPresentation
          v-if="!isQuickWinForm && !(isDescriptionsInverted || isCrowdfunding)"
          :is-inverted-description="isDescriptionsInverted"
        />
        <template v-else>
          <div v-if="isDescriptionsInverted && description" class="CampaignPresentation Card">
            <p class="CampaignForm--Description">
              <span>{{ description }}</span>
            </p>
          </div>
        </template>
      </template>
      <StepperOrganizationPurpose v-if="showIFP" />
      <StepperFiscalReduction v-if="!isWidget && isFirstStep && form && form.taxSystem" />
      <StepperContributors v-if="showContributors" />
      <!-- Do not remove comment #21063 -->
      <!-- <StepperSeoTags v-if="!$device.isMobileOrTablet && isFirstStep && !isWidget" /> -->
    </div>
    <div class="CampaignCards Column Is-One-Quarter">
      <FormRaisingState v-if="showColumnRaisingState" />
      <StepperSocialLinks v-if="!$device.isMobile && !isQuickWinForm" />
      <StepperHeaderSticky
        v-if="isFirstStep && isCrowdfunding"
        :is-campaign-ended="isCampaignEnded"
        :is-sale-ended="isSaleEnded"
        :sale-end-date="form.saleEndDate"
      />
      <StepperContact v-if="isFirstStep" />
      <VoluntaryContributionCard v-if="isQuickWinForm" class="voluntary-card--desktop" />
      <CartSummaryLight v-if="step > 1 && step < 4" :total-amount.sync="totalAmount"/>
      <CardSecurity v-if="!isQuickWinForm" />
      <!-- Do not remove comment #21063 -->
      <!-- <CardSeoTags v-if="$device.isMobileOrTablet && isFirstStep && !isWidget" /> -->
      <ActionReport v-if="isFirstStep && !isQuickWinForm" />
    </div>
    <ModalExpiredSession
      v-if="displayModalExpiredSession"
      @close="closeExpiredModalAndRedirectStepOne()"
    />
  </div>
</template>

<script>
import { get, capitalize, filter, forEach } from 'lodash-es'
import { ValidationObserver } from 'vee-validate'
import { HaButton, HaJumbotron, HaTile } from '@ha/components'
import { FormSteps } from '@/helpers/formConfig'
import { enum as enums, haMoment } from '@ha/helpers'

import FormHeaderTest from '@/components/abTests/FormHeaderTest.vue'
import FormHeader from '../informations/FormHeader.vue'
import FormStepper from '@/components/globals/stepper/FormStepper.vue'
import StepperHeaderSticky from '@/components/forms/stepper/partials/StepperHeaderSticky.vue'
import CardSecurity from '@/components/globals/informations/CardSecurity.vue'
import StepperContact from '@/components/forms/stepper/partials/StepperContact.vue'
import CartSummaryLight from '@/components/carts/summary/CartSummaryLight.vue'
import FormPresentation from '@/components/forms/informations/FormPresentation.vue'
import NoticeVoluntaryContribution from '~/components/voluntary-contributions/NoticeVoluntaryContribution.vue'
import NoticeVoluntaryContributionTest from '@/components/abTests/NoticeVoluntaryContributionTest.vue'
import StepperFiscalReduction from '@/components/forms/stepper/partials/StepperFiscalReduction.vue'
import StepperOrganizationPurpose from '@/components/forms/stepper/partials/StepperOrganizationPurpose.vue'
import ModalExpiredSession from '@/components/carts/session/ModalExpiredSession.vue'
import FormBlockedCollectBanner from '@/components/forms/informations/FormBlockedCollectBanner.vue'
import StepperContributors from '@/components/forms/stepper/partials/StepperContributors.vue'
import FormRaisingState from '@/components/forms/informations/FormRaisingState.vue'
import StepperSocialLinks from '@/components/forms/stepper/partials/StepperSocialLinks.vue'
import StepperSeoTags from '@/components/forms/stepper/partials/StepperSeoTags.vue'
import metaInformationsCampaign from '@/mixins/metaInformationsCampaign'
import ActionReport from '@/components/reports/ActionReport.vue'
import { routeCodes, handledHTTPErrors } from '@/helpers/errorLogs'
import useTracking from '@/composables/useTracking'
import useCart from '@/composables/useCart'
import useCartRefresh from '@/composables/useCartRefresh'
import useModalities from '@/composables/useModalities'
import useParticipantInformation from '@/components/participants/useParticipantInformation'
import useAbTasty from '~/composables/useAbTasty'
import VoluntaryContributionCardTest from '@/components/abTests/VoluntaryContributionCard.vue'
import VoluntaryContributionCard from '~/components/voluntary-contributions/VoluntaryContributionCard.vue'
import HeaderCVDropdown from '~/components/abTests/HeaderCVDropdown.vue'
import useFormType from '@/composables/useFormType'
import Reassurance from '@/components/globals/informations/Reassurance.vue'
import AmountControls from '@/components/globals/stepper/AmountControls.vue'

export default {
  name: 'Step',
  components: {
    ActionReport,
    ValidationObserver,
    HaButton,
    HaJumbotron,
    HaTile,
    FormStepper,
    CardSecurity,
    StepperContact,
    CartSummaryLight,
    FormPresentation,
    NoticeVoluntaryContribution,
    NoticeVoluntaryContributionTest,
    VoluntaryContributionCard,
    VoluntaryContributionCardTest,
    StepperFiscalReduction,
    ModalExpiredSession,
    FormBlockedCollectBanner,
    StepperHeaderSticky,
    StepperOrganizationPurpose,
    StepperContributors,
    FormRaisingState,
    StepperSocialLinks,
    FormHeader,
    FormHeaderTest,
    StepperSeoTags,
    HeaderCVDropdown,
    Reassurance,
    AmountControls
  },
  mixins: [metaInformationsCampaign],
  inject: ['$notifications', 'isWidget'],
  props: {
    currentStepComponent: {
      type: Object
    },
    step: {
      type: Number,
      required: true
    },
    navigate: {
      type: Function
    },
    isWidget: Boolean
  },
  setup() {
    const { isCartExpired, recordAction } = useCartRefresh()
    const { trackingMethods } = useTracking()
    const {
      state: { isFileUploading }
    } = useParticipantInformation()
    const { initAbTasty, variation, isVariationB, isVariationC, isVariationD, isVariationE } =
      useAbTasty()

    const { cartHasScheduledPayments } = useCart()
    const { isPaymentTermsEnabled } = useModalities()
    const { isQuickWinForm } = useFormType()
    return {
      trackingMethods,
      isCartExpired,
      recordAction,
      isFileUploading,
      variation,
      initAbTasty,
      isVariationB,
      isVariationC,
      isVariationD,
      isVariationE,
      isQuickWinForm,
      cartHasScheduledPayments,
      isPaymentTermsEnabled
    }
  },
  data() {
    return {
      goToNextStep: null,
      canClickNext: false,
      displayModalExpiredSession: false,
      isFetching: false,
      isBacking: false,
      canCheckForm: false,
      totalAmount: 0
    }
  },
  beforeMount() {
    this.initAbTasty('abcdTestFirstStepCV')
  },
  computed: {
    isFirstStep() {
      return this.step === 1
    },
    isLastStep() {
      return this.step === this.formSteps.length
    },
    cart: {
      cache: false,
      get() {
        return this.$store.getters['carts/getCart'](this.metadata)
      }
    },
    // checks crowdfunding specific parameters (can also be set for membership in BO)
    // used to display raising state or not
    showCardNumbers() {
      return (
        this.isFirstStep &&
        (this.form.amountVisible ||
          this.form.displayParticipantsCount ||
          (this.form.saleEndDate && !this.isSaleEnded) ||
          (this.form.endDate && !this.isCampaignEnded) ||
          this.saleIsNotYetOpen)
      )
    },
    // collect counter for mobile or widget
    showFullRaisingState() {
      return (this.$device.isMobileOrTablet || this.isWidget) && this.showCardNumbers
    },
    // collect counter for desktop (in a column)
    showColumnRaisingState() {
      return this.$device.isDesktop && this.showCardNumbers
    },
    isCollectAllowed() {
      return get(this.compliance, 'allowCollect', true)
    },
    showContributors() {
      return this.isFirstStep && this.isCrowdfunding
    },
    compliance() {
      return this.$store.getters['organizations/getOrganizationCompliance'](
        this.metadata.organization
      )
    },
    showIFP() {
      return (
        this.isFirstStep &&
        this.form &&
        this.isCrowdfunding &&
        (get(this.compliance, 'organizationPurpose') || get(this.compliance, 'rna'))
      )
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    isFormAdmin() {
      return get(this.form, 'hasAdminRights', false)
    },
    formSteps() {
      if ((this.isShop || this.isCrowdfunding) && !this.hasCustomFieldsOrOptions) {
        return FormSteps[this.metadata.type].filter((_, index) => index !== 1)
      }
      return FormSteps[this.metadata.type]
    },
    formTypes() {
      return enums.FormType
    },
    isShop() {
      return this.formTypes.SHOP === this.form.formType
    },
    isCrowdfunding() {
      return this.formTypes.CROWDFUNDING === this.form.formType
    },
    hasCustomFieldsOrOptions() {
      let hasOptionOrCustomFields = false
      forEach(this.form.tiers, tier => {
        if (tier?.customFields?.length > 0 || tier?.extraOptions?.length > 0) {
          hasOptionOrCustomFields = true
        }
      })
      return hasOptionOrCustomFields
    },
    stepSize() {
      return this.formSteps.length
    },
    stepName() {
      return this.$t(this.formSteps[this.step - 1].title)
    },
    description() {
      return get(this, 'form.description', '')
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    organismPageUrl() {
      const { organizationSlug } = this.$route.params
      return `${process.env.NUXT_ENV_BASE_URL}/associations/${organizationSlug}`
    },
    /**
     * Whether the <Campaign/> can display the Previous button
     * @return {boolean}
     */
    canDisplayPrevious() {
      return this.step > 1 && this.step < this.stepSize && !this.isQuickWinForm
    },
    /**
     * Whether the <Campaign/> can display the Next button
     * @return {boolean}
     */
    canDisplayNext() {
      return this.step < this.stepSize && !(this.isCrowdfunding && this.isFirstStep)
    },
    displayControls() {
      return this.step < this.stepSize
    },
    previousButtonText() {
      return this.$t('campaign.previousStep')
    },
    nextButtonText() {
      return this.isFirstStep
        ? this.$t('campaign.order')
        : this.step === this.stepSize
        ? this.payText
        : this.$t('campaign.nextStep')
    },
    isDisabledPrevious() {
      return !!this.isBacking && !this.isQuickWinForm
    },
    isDisabledNext() {
      return !this.canClickNext || this.isFetching || this.isFileUploading
    },
    isInvalidForm() {
      return this.isFirstStep && this.canCheckForm && !this.canClickNext
    },
    payText() {
      return this.totalPrice && this.totalPrice > 0
        ? this.$t('campaign.validateAndPay')
        : this.$t('campaign.validate')
    },
    totalPrice: {
      cache: false,
      get() {
        return this.$store.getters['carts/getCartTotal'](this.metadata)
      }
    },
    isDescriptionsInverted() {
      return get(this, 'form.invertDescriptions')
    },
    isCampaignEnded() {
      return get(this, 'form.endDate') ? new Date(this.form.endDate).getTime() < Date.now() : false
    },
    isSaleEnded() {
      return get(this, 'form.saleEndDate')
        ? new Date(this.form.saleEndDate).getTime() < Date.now()
        : false
    },
    saleIsNotYetOpen() {
      return get(this, 'form.saleStartDate')
        ? new Date(this.form.saleStartDate).getTime() > Date.now()
        : false
    },
    isClosed() {
      return this.isCampaignEnded || this.isSaleEnded || this.isSoldOut || this.saleIsNotYetOpen
    },
    isSoldOut() {
      // event is not sold out for a user that has a cart
      // remainingEntries = sold entries + reserved entries
      const allEntriesReserved = get(this, 'form.remainingEntries') === 0
      return !this.cart && allEntriesReserved
    },
    hasFiscalReductionTiers() {
      const tiers = get(this, 'form.tiers', [])
      const tickets = filter(tiers, {
        tierType: 'Registration'
      })

      let hasFiscalReductionTiers = false
      forEach(tickets, ticket => {
        if (ticket.isEligibleTaxReceipt) {
          hasFiscalReductionTiers = true
        }
      })
      return hasFiscalReductionTiers
    },
    jumbotronTitle() {
      if (this.isSaleEnded && !this.isCampaignEnded) {
        return this.$t(`jumbotron.title.isSale${capitalize(this.metadata.type)}Ended`)
      }
      if (this.isCampaignEnded) {
        return this.$t(`jumbotron.title.is${capitalize(this.metadata.type)}Ended`)
      }
      if (this.saleIsNotYetOpen) {
        return this.$t(`jumbotron.title.isSale${capitalize(this.metadata.type)}NotYetOpen`, [
          haMoment(this.form.saleStartDate).format('DD/MM/YYYY')
        ])
      }
      if (this.isSoldOut) {
        return this.$t('jumbotron.title.isSoldOut')
      }
      return ''
    },
    wordingAmountTicket() {
      return this.isPaymentTermsEnabled && !this.isFirstStep ? 'amountTicketsModalities' : 'amountTickets'
    },
    priceLabel() {
      // priceLabel is only used for quick wins atm (no crowd)
      // if (isCrowdfunding.value && cartItems.value[0]?.tierType === 'MonthlyDonation')
      // return i18n.t('total.monthlyDonation')
      if (this.isFirstStep) {
        return this.$t(`total.type.${this.form.formType.toLowerCase()}.${this.wordingAmountTicket}`).toString()
      }
      return this.cartHasScheduledPayments ? this.$t('total.toBePaidToday') : this.$t('total.amountTTC')
    }
  },
  watch: {
    isCartExpired() {
      if (this.isCartExpired) this.displayModalExpiredSession = true
    },
    canClickNext() {
      if (this.canClickNext) this.canCheckForm = false
    }
  },
  methods: {
    trackStepOrPayment() {
      if (this.step === this.formSteps.length) {
        this.trackingMethods?.onFormSubmission()
      } else this.trackingMethods?.onFormStepChange({ step: this.step, step_name: this.stepName })
    },
    closeExpiredModalAndRedirectStepOne() {
      this.displayModalExpiredSession = false
      this.$store.dispatch('carts/removeCart', this.metadata)
      this.$router.push(
        `/associations/${this.metadata.organization}/${this.metadata.url_type}/${
          this.metadata.slug
        }${this.isWidget ? '/widget' : ''}`
      )
    },
    onFormInvalid() {
      this.$notifications.push({
        type: 'danger',
        title: this.$t(`campaign.error.missingInfo`),
        body: this.$t(`campaign.error.fillMandatory`),
        timeout: 5000
      })
    },
    async onNextStep() {
      this.canCheckForm = true
      if (this.isQuickWinForm && !this.canClickNext && !this.isLastStep) {
        this.$notifications.push({
          type: 'danger',
          title: this.$t(`campaign.error.missingInfo`),
          body: this.$t(`campaign.error.emptyCart`),
          timeout: 5000
        })
        return
      }
      this.isFetching = true
      this.goToNextStep(this.metadata)
        .then(() => {
          this.trackStepOrPayment()
          if (this.step < this.stepSize) {
            this.navigate(this.step + 1)
          }
        })
        .catch(error => {
          this.isFetching = false

          const status = error?.response?.status.toString() || 'uncaught'
          const translationKey = handledHTTPErrors.includes(status) ? status : 'uncaught'

          const toast = {
            type: 'danger',
            title: this.$t(`error.code.${translationKey}.title`),
            body:
              error.message ||
              error.response?.data.errors?.[0]?.message ||
              `${this.$t(`error.code.${translationKey}.message`)} - ${status} ${
                routeCodes[error.routeCode]
              }`,
            timeout: 5000
          }

          this.$notifications.push(toast, error)
          if (!error.noFetch) {
            this.$store.dispatch('forms/fetchForm', this.metadata)
          }
        })
    },
    onPreviousStep() {
      this.isBacking = true
      this.navigate(this.step - 1 || 1)
    },
    onStepChange(step) {
      if (step <= this.formSteps.length - 1) {
        this.navigate(step)
      }
    }
  }
}
</script>

<style lang="scss">
.CampaignForm {
  &--Wrapper {
    margin-top: $ha-spacing-large;
  }

  .FormError .QuantitySelector .Input--Wrapper .Input {
    background-color: color-mix(in srgb, var(--ha-color-danger) 25%, var(--ha-color-white));
    border: 2px solid var(--ha-color-danger);
  }

  &--Description {
    white-space: pre-line;
  }

  &--Expiration {
    margin-top: $ha-spacing-large;
  }

  &--Controls {
    &-Fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: $ha-spacing-medium;
      background-color: var(--ha-color-white);

      @include mediaQuery(600) {
        position: relative;
        flex-direction: row;
        justify-content: flex-end;
        margin: $ha-spacing-large 0;
        padding: 0;
        background-color: transparent;
      }

      &.SingleButton .HaButton-Fill {
        width: 100%;

        @include mediaQuery(600) {
          width: auto;
        }
      }

      &.FormAdmin {
        bottom: 60px;

        @include mediaQuery(600) {
          bottom: auto;
        }
      }

      .ValidatingButton {
        color: #D1D6DE !important;
      }

      button + button {
        @include mediaQuery(600) {
          margin-bottom: 0;
          margin-left: $ha-spacing-small;
        }
      }

      .HaButton-Fill {
        white-space: nowrap;
      }

      .PreviousButton {
        width: 48%;
        border: $ha-border-regular !important;

        @include mediaQuery(600) {
          width: auto;
          border: none;
        }
      }

      .NextButton {
        width: 48%;

        @include mediaQuery(600) {
          width: auto;
        }
      }
    }

    &-Widget {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      margin: $ha-spacing-large 0;
      padding: 0 $ha-spacing-large;

      @include mediaQuery(600) {
        flex-direction: row;
        padding: 0;
      }

      button + button {
        margin-bottom: $ha-spacing-small;

        @include mediaQuery(600) {
          margin-bottom: 0;
          margin-left: $ha-spacing-small;
        }
      }
    }
  }
}

.Step {
  &Transition {
    padding: $ha-spacing-large;
    background-color: var(--ha-color-white);
    border-radius: 0 0 $ha-radius-large $ha-radius-large;
  }

  .RequiredFields {
    display: block;
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-tiny;
  }
}

.Campaign-HelloAsso {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: $ha-spacing-medium;
  word-wrap: break-word;
  background-color: var(--ha-color-white);
  border: $ha-border-width-regular solid set-alpha(border, 0.5);
  border-radius: 0 0 $ha-radius-regular $ha-radius-regular;
}

.CampaignContent {
  min-height: $ha-unit * 62.5; // 500px

  .HaToast {
    width: 100%;
    margin-top: $ha-spacing-medium;
    background: var(--ha-color-white);

    .HaToast--Close {
      border: none;
    }
  }
}

.CampaignCards {
  p {
    font-size: $ha-font-size-small;
  }
}
</style>

<style lang="scss" scoped>
/**
 * Animations
 */

.slide-fade-enter-active {
  transition: all 0.3s;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: scale3d(0.975, 0.975, 0.975);
  opacity: 0;
}

// to clean up after tests
.ABTest {
  padding: $ha-spacing-medium;
  background-color: var(--ha-color-air);
}

.voluntary-card {
  &--mobile {
    display: block;

    @include mediaQuery(900) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include mediaQuery(900) {
      display: block;
    }
  }
}
</style>
