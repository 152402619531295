<template>
  <div
    :class="{ Card: !isDonation, DonationHeader: isDonation }"
    class="CampaignHeader"
    data-testid="campaign-header"
  >
    <div class="CampaignHeader__heading">
      <h1 class="CampaignHeader--Title" data-testid="title">
        <slot name="title">{{ title }}</slot>
      </h1>
      <ClientOnly v-if="isQuickWinForm && step === 1">
        <ShareButton />
      </ClientOnly>
    </div>
    <h2 class="CampaignHeader--Subtitle">
      <slot name="subtitle">
        <template v-if="organizationName">
          {{ byWording }}
          <ha-button
            :href="`/associations/${metadata.organization}`"
            target="_blank"
            rel="noopener noreferrer"
            variant="link"
            :data-ux="`Forms_${metadata.type}_CampaignHeader_OrganismPage`"
          >
            {{ organizationName }}
          </ha-button>
        </template>
      </slot>
    </h2>
    <span v-if="dateLabel" :class="['CampaignHeader--Date', { 'CampaignHeader--Date--quickWin': isQuickWinForm }]">
      <slot name="date">
        <fa icon="calendar-alt" />
        {{ dateLabel }}
      </slot>
    </span>
    <client-only>
      <section v-if="step === 1 && $device.isMobile && !isDonation && !isQuickWinForm" class="CampaignHeader--Share">
        <ShareLinks v-if="!isNavigatorShare" with-url-copy />
        <button
          v-if="isNavigatorShare"
          class="CampaignHeader--ShareButton"
          :data-ux="`Forms_${metadata.type}_CampaignHeader_ShareMobile`"
          @click="navigatorShare"
        >
          <fa icon="share-alt" />
        </button>
      </section>
    </client-only>
    <p
      ref="description"
      v-if="description && !form.invertDescriptions"
      :class="[
        'CampaignHeader--Description',
        {
          collapsed: isQuickWinForm && isDescriptionCollapsed,
          gradient: isLongDescription
        }
      ]"
    >
      <span>{{ description }}</span>
      <FormPresentation v-if="isQuickWinForm" :is-inverted-description="form.invertDescriptions" />
    </p>
    <button
        type="button"
        class="collapse-control"
        v-if="isQuickWinForm && isLongDescription && !form.invertDescriptions"
        @click="isDescriptionCollapsed = !isDescriptionCollapsed"
      >
        {{ isDescriptionCollapsed ? $t('campaignHeader.displayMore'): $t('campaignHeader.displayLess') }}
        <fa :icon="isDescriptionCollapsed ? 'chevron-down' : 'chevron-up'" />
      </button>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { HaButton } from '@ha/components'
import { getDateLabel } from '@/helpers/formUtils'
import { enum as enums, haMoment } from '@ha/helpers'
import FormPresentation from '@/components/forms/informations/FormPresentation.vue'
import useFormType from '@/composables/useFormType'
import ShareLinks from '@/components/globals/share/ShareLinks.vue'
import ShareButton from '@/components/forms/informations/partials/ShareButton.vue'

export default {
  name: 'CampaignHeader',
  components: {
    HaButton,
    ShareLinks,
    FormPresentation,
    ShareButton
  },
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isDescriptionCollapsed: true,
      isLongDescription: false
    }
  },
  setup() {
    const { isQuickWinForm } = useFormType()
    return {
      isQuickWinForm
    }
  },
  mounted() {
    this.isLongDescription = this.$refs.description.scrollHeight > this.$refs.description.clientHeight
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    organizationName() {
      return get(
        this.$store.getters['organizations/getOrganization'](this.metadata.organization),
        'name'
      )
    },
    title() {
      return get(this, 'form.title')
    },
    description() {
      return get(this, 'form.description', '')
    },
    startDate() {
      return get(this, 'form.startDate')
    },
    endDate() {
      return get(this, 'form.endDate')
    },
    formTypes() {
      return enums.FormType
    },
    isDonation() {
      return this.formTypes.DONATION === this.form.formType
    },
    dateLabel() {
      // get the right date label dependings of the type of membership
      if ([this.formTypes.MEMBERSHIP].includes(this.form.formType)) {
        let label = ''
        switch (get(this.form, 'validityType')) {
          case 'MovingYear':
            label = this.$t('campaign.date.with2Dates.withNoTime', [
              haMoment(this.startDate).format('DD MMMM YYYY'),
              haMoment(this.startDate).add(1, 'year').format('DD MMMM YYYY')
            ])
            break
          case 'Illimited':
            return ''
          case 'Custom':
            label = this.$t('campaign.date.with2Dates.withNoTime', [
              haMoment(this.startDate).format('DD MMMM YYYY'),
              haMoment(this.endDate).format('DD MMMM YYYY')
            ])
            break
          default:
            break
        }
        return `${this.$t('campaign.membershipFrom')} ${label.toLowerCase()}`
      }
      return getDateLabel(this.startDate, this.endDate, this.$i18n)
    },
    isNavigatorShare() {
      return process.client && navigator.share
    },
    byWording() {
      return this.isDonation ? this.$t('campaignHeader.inFavour') : this.$t('campaignHeader.by')
    }
  },
  methods: {
    navigatorShare() {
      if (navigator.share) {
        navigator.share({
          title: this.title,
          text: this.description,
          url: window.location.href
        })
      }
    }
  }
}
</script>

<style lang="scss">
.CampaignHeader {
  &__heading {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  &--Title {
    margin-bottom: $ha-spacing-mini;
    padding-right: $ha-spacing-big;
    font-size: $ha-font-size-jumbo;
    line-height: $ha-line-height-small;
  }

  &--Subtitle {
    margin: 0;
    color: var(--ha-color-text-light);
    font-weight: $ha-font-weight-regular;
    font-size: $ha-font-size-big;

    > .HaButton {
      font-size: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--Date {
    display: block;
    margin-top: $ha-spacing-small;
    margin-bottom: $ha-spacing-mini;
    color: var(--ha-color-text-lightest);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;

    &--quickWin {
      color: #505870;
    }

    svg {
      margin-right: $ha-spacing-mini;
    }
  }

  &--Description {
    position: relative;
    margin-top: $ha-spacing-large;
    margin-bottom: 0;
    padding-top: $ha-spacing-medium;
    font-size: $ha-font-size-regular;
    white-space: pre-line;
    word-break: break-word;
    border-top: $ha-border-width-regular solid set-alpha(border, 0.5);

    &.collapsed {
      max-height: 5lh;
      overflow: hidden;

    }
  }

  &:has(.collapsed) {
    .gradient{
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: transparent;
        background: linear-gradient(to bottom, transparent, var(--ha-color-white) 90%);
        content: '';
        pointer-events: none;
      }
    }
  }

  .collapse-control {
    width: fit-content;
    padding: 0;
    color: var(--ha-color-primary);
    line-height: 0;
    text-decoration: underline;
    cursor: pointer;

    svg {
      margin-left: 1rem;

      path {
        color: var(--ha-color-primary);
      }
    }
  }

  &--ShareButton {
    position: absolute;
    top: $ha-unit * 1.5; // 24px
    right: $ha-unit * 4.5; // 36px
    //width: $ha-unit * 4;
    //height: $ha-unit * 4;
    padding: $ha-spacing-small;
    color: var(--ha-color-text-light);
    outline: none;
  }

  .ShareLinks {
    margin-top: $ha-spacing-mini;
  }
}
</style>
