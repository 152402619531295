import { render, staticRenderFns } from "./AnimatedIllustration.vue?vue&type=template&id=6ace7584&scoped=true"
import script from "./AnimatedIllustration.vue?vue&type=script&setup=true&lang=js"
export * from "./AnimatedIllustration.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AnimatedIllustration.vue?vue&type=style&index=0&id=6ace7584&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ace7584",
  null
  
)

export default component.exports