<template>
  <div class="select-donation-payment-date">
    <validation-provider
      v-slot="{ errors, valid, validated, failedRules }"
      :rules="`REQUIRED|min_value:1|max_value:${maxPaymentDay}`"
      slim
      class="select-donation-payment-date__field"
    >
      <ha-form-field :is-valid="validated ? valid : null">
        <p>
          {{ $t('campaign.field.paymentDate.paymentDay_01') }}
          <ha-input
            v-model="paymentDay"
            type="number"
            :max-length="2"
            :hide-counter="true"
            step="1"
            min="1"
            :max="maxPaymentDay"
            placeholder="5"
            data-testid="input-due-date"
            @change="$emit('update-payment-day', paymentDay)"
            @keydown="allowOnlyNumericEntry"
            @focus="trackFirstClick"
          />
          {{ $t('campaign.field.paymentDate.paymentDay_02') }}
        </p>
        <template #error>
          <template v-if="failedRules.REQUIRED">
            {{ $t('campaign.field.paymentDate.paymentDayErrorRequired') }}
          </template>
          <template v-else-if="failedRules.min_value || failedRules.max_value">
            {{ $t('campaign.field.paymentDate.paymentDayErrorDay', [maxPaymentDay]) }}
          </template>
          <template v-else>
            {{ errors[0] }}
          </template>
        </template>
      </ha-form-field>
    </validation-provider>
    <p class="select-donation-payment-date__field__desc">
      {{ $t('campaign.field.paymentDate.nextPayment') }}
      <strong data-testid="due-date-selected" {{ nextPaymentDate }}. </strong>
    </p>
  </div>
</template>

<script>
import { haMoment, functions } from '@ha/helpers'
import { ValidationProvider } from 'vee-validate'
import { HaInput, HaFormField } from '@ha/components'
import useTracking from '@/composables/useTracking'
import useStoreData from '@/composables/useStoreData'

export default {
  name: 'SelectDonationPaymentDate',
  components: {
    HaInput,
    HaFormField,
    ValidationProvider
  },
  setup() {
    const { trackingMethods } = useTracking()
    const { onFirstClick } = trackingMethods
    const { organization } = useStoreData()

    return { onFirstClick, organization }
  },
  data() {
    return {
      paymentDay: Math.min(haMoment().day(), 28),
      maxPaymentDay: 28
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    nextPaymentDate() {
      if (!this.paymentDay || this.paymentDay > this.maxPaymentDay || this.paymentDay < 1)
        return 'jj/mm/aaaa'
      const today = haMoment().day(this.paymentDay).add(1, 'month')
      return today.format('DD MMMM YYYY')
    },
    trackingData() {
      return {
        step: 0,
        step_name: 'Don'
      }
    }
  },
  methods: {
    allowOnlyNumericEntry(event) {
      functions.allowOnlyNumericEntry(event)
    },
    trackFirstClick() {
      this.onFirstClick('First Interaction Campaign', {
        step: 0,
        step_name: 'Don'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.select-donation-payment-date {
  margin-top: $ha-spacing-medium;
  padding: $ha-spacing-medium;
  text-align: left;
  background: var(--ha-color-background);
  border-radius: $ha-radius-large;

  &__field {
    &__input,
    .HaFormField--Field,
    .Input--Wrapper,
    input {
      display: inline;
      max-width: calc($ha-unit * 5);
    }

    .Input--Wrapper .Input {
      min-height: $ha-unit;
      margin: 0 $ha-unit * 0.5;
      padding: calc($ha-unit * 0.5) $ha-unit;
    }

    &__desc {
      margin: $ha-spacing-medium 0 0 0;

      strong {
        color: var(--ha-color-primary);
      }
    }
  }
}
</style>
