<script setup>
import { functions } from '@ha/helpers'
import { HaInput } from '@ha/components'
import { computed } from 'vue'

import useTipEdition from '@/components/voluntary-contributions/partials/TipSelectors/useTipEdition'
import Range from '@/components/voluntary-contributions/partials/AbTestComponents/Range'

const props = defineProps({
  tip: { type: [String, Number], required: true },
  tipSuggestions: { type: Object, required: true },
  isBigStep: {type: Boolean}
})

const emit = defineEmits(['update:tip'])
const { updateTip, lastStoredValue, dataUxInput } = useTipEdition(props, emit)
const step = computed(() => props.isBigStep ? '0.5' : '0.1')

// round to nearest superior 0.5 (to fit the 0.5 and 0.1 steps)
const maxRange = computed(() => Math.ceil(props.tipSuggestions.max * 2)/2)

const onInputBlur = () => {
  if (!props.tip) updateTip(lastStoredValue.value)
}

</script>

<template>
  <div class="range-selector">
    <Range
    :min="0"
    :max="maxRange.toFixed(2)"
    :step="step"
    :range-value="tip"
    @input="updateTip"
    ></Range>
    <div class="range-selector__custom-field">
      <span class="range-selector__label">
      {{ $t('abTasty.modal.contributionSelection.contributeUpTo') }}
    </span>
      <HaInput
        class="range-selector__custom-field__input"
        :value="tip"
        :data-ux="dataUxInput"
        type="number"
        step="0.1"
        data-testid="input-cv-amount"
        @input="updateTip"
        @blur="onInputBlur"
        @keydown="functions.allowOnlyNumericEntry($event, { allowDecimal: true })"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

.range-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* stylelint-disable-next-line */
  margin-top: 1rem;

  @include mediaQuery(600) {
    margin-top: 0;
  }

  &__label {
      width: auto;
      color: var(--ha-color-text);
      font-weight: bold;
      font-size: $ha-font-size-regular;
      white-space: no-wrap;
    }

  &__cursor {
    width: 100%;
  }

  &__custom-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 350px;
    margin-top: $ha-spacing-big;

    &__input {
      display: flex;
      align-items: center;
      width: 35%;

      &::after {
        margin-left: -$ha-spacing-large;
        font-weight: $ha-font-weight-bold;
        content: '€';
      }

      :deep(.Input) {
        padding-right: $ha-spacing-big;
        color: var(--ha-color-text);
        font-weight: $ha-font-weight-bold;
        font-size: $ha-font-size-jumbo;
        text-align: left;
        border-top-right-radius: $ha-radius-regular !important; // provisional styles overwrite must be removed in ticket #18508
        border-bottom-right-radius: $ha-radius-regular !important;
      }
    }
  }
}
</style>
