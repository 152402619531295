var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['price', { 'price-modalities-enabled': _vm.isModalitiesEnabled }],attrs:{"data-testid":"total-price"}},[_c(_setup.HaOverlay,[_c('div',{staticClass:"priceWrapper"},[(_vm.hasTerms)?_c('section',{staticClass:"priceTotal"},[_c('span',{staticClass:"price--Label"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.totalLabel)+" ")]})],2),_vm._v(" "),_c('div',{staticClass:"price--Amount",attrs:{"data-test":"total-amount"}},[_vm._v(_vm._s(_vm.totalAmount)+" €")])]):_vm._e(),_vm._v(" "),_c('section',{class:[
          'priceTotal',
          { 'priceTotal-modalities-enabled': _vm.isModalitiesEnabled },
        ]},[_c('span',{staticClass:"price--Label"},[_c('span',[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2),_vm._v(" "),(
              _setup.isPaymentTermsEnabled &&
              _setup.isLastStep &&
              _setup.secondInstallmentRealAmountWithTip
            )?_c('span',{staticClass:"ToPayToday"},[_vm._v(_vm._s(_vm.$t(`total.type.${_setup.form.formType.toLowerCase()}.today`)))]):_vm._e(),_vm._v(" "),(
              _setup.isPaymentTermsEnabled &&
              _setup.isLastStep &&
              !_setup.secondInstallmentRealAmountWithTip
            )?_c('span',{staticClass:"price-modalities price-modalities--subtitle"},[_vm._v(_vm._s(_vm.$t(`total.type.${_setup.form.formType.toLowerCase()}.subtitle`)))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"price--Amount",attrs:{"data-test":_vm.hasTerms ? 'total-today' : 'total-amount'}},[_vm._v("\n          "+_vm._s(_setup.priceAmount)+" €\n        ")])]),_vm._v(" "),(_vm.vat > 0)?_c('section',{staticClass:"priceVat"},[_c('span',{staticClass:"price--Label"},[_vm._v("\n          "+_vm._s(_vm.$t("total.amountWithVAT"))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"Price--Amount",attrs:{"data-testid":"total-vat"}},[_vm._v(_vm._s(_setup.vatAmount)+" €")])]):_vm._e(),_vm._v(" "),(_setup.isPaymentTermsEnabled && _setup.isLastStep && !_setup.$device.isMobile)?[(_setup.secondInstallmentRealAmountWithTip)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t(`total.type.${_setup.form.formType.toLowerCase()}.installmentText1`, [
              _setup.secondInstallmentRealAmountWithTip,
            ]))+"\n        ")]):_vm._e()]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }