var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.HaDropdown,{attrs:{"alignment":"right"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c(_setup.HaButton,{staticClass:"cta",attrs:{"variant":"outline","icon-side":"right"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(_setup.FontAwesomeIcon,{attrs:{"icon":_setup.faShareNodes,"fixed-width":""}})]},proxy:true}])},[_c('span',{staticClass:"cta__text"},[_vm._v("Partager la campagne")])])]},proxy:true},{key:"content",fn:function(){return [_c(_setup.HaMenuList,[_vm._l((_setup.items),function(item){return _c(_setup.HaMenuListItem,{key:item.id,class:`HaMenuListItem-${
          item.name.charAt(0).toUpperCase() +
          item.name.slice(1)
        }`,attrs:{"icon":item.icon,"label":item.label,"data-ux":item.dataUx},on:{"click":function($event){return _setup.openSharePopup(item.href, false, item.label)}}})}),_vm._v(" "),_c(_setup.HaMenuListItem,{staticClass:"HaMenuListItem-Email",attrs:{"icon":_setup.faSquareEnvelope,"label":_setup.i18n.t('share.buttonLabel.email.name'),"href":`mailto:?subject=${_setup.i18n.t(`share.prefilledText.email.${_setup.form.formType.toLowerCase()}`, [
            _setup.form.title])}&body=${_setup.form.url}`,"rel":"nofollow"}}),_vm._v(" "),_c(_setup.HaMenuListItem,{staticClass:"HaMenuListItem-CopyUrl",attrs:{"icon":_setup.faLink,"label":_setup.i18n.t('share.buttonLabel.copyUrl')},on:{"click":function($event){return _setup.copyUrl(_setup.form.url)}}}),_vm._v(" "),(_setup.isNativeShareAvailable)?_c(_setup.HaMenuListItem,{staticClass:"HaMenuListItem-genericShare",attrs:{"icon":_setup.faGearCode,"label":_setup.i18n.t(
            'share.buttonLabel.genericShare.name'
          )},on:{"click":function($event){_setup.openSharePopup(null, true, _setup.i18n.t('share.buttonLabel.genericShare.name'))}}}):_vm._e()],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }