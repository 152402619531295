<script setup>
import { computed } from 'vue'
import { haMoment, functions } from '@ha/helpers'
import { ValidationProvider } from 'vee-validate'
import { HaInput, HaFormField } from '@ha/components'

const props = defineProps({
  dayOfLevy: {
    type: Number,
    required: true
  },
  maxDayInMonth: {
    type: Number,
    default: 28
  }
})

const nextLevyDate = computed(() => {
  return !props.dayOfLevy || props.dayOfLevy > props.maxDayInMonth || props.dayOfLevy < 1
    ? 'jj/mm/aaaa'
    : haMoment().day(props.dayOfLevy).add(1, 'month').format('DD MMMM YYYY')
})
</script>

<template>
  <div class="select-donation-payment-date">
    <validation-provider
      v-slot="{ errors, valid, validated, failedRules }"
      :rules="`REQUIRED|min_value:1|max_value:${props.maxDayInMonth}`"
      slim
      class="select-donation-payment-date__field"
    >
      <HaFormField :is-valid="validated ? valid : null">
        <span>
          {{ $t('campaign.field.paymentDate.paymentDay_01') }}
        </span>
        <HaInput
          :value="props.dayOfLevy"
          type="number"
          :max-length="2"
          :hide-counter="true"
          step="1"
          min="1"
          :max="props.maxDayInMonth"
          placeholder="5"
          @input="$emit('update:dayOfLevy', parseInt($event))"
          @keydown="functions.allowOnlyNumericEntry"
        />
        <span>
          {{ $t('campaign.field.paymentDate.paymentDay_02') }}
        </span>
        <template #error>
          <template v-if="failedRules.REQUIRED">
            {{ $t('campaign.field.paymentDate.paymentDayErrorRequired') }}
          </template>
          <template v-else-if="failedRules.min_value || failedRules.max_value">
            {{ $t('campaign.field.paymentDate.paymentDayErrorDay', [props.maxDayInMonth]) }}
          </template>
          <template v-else>
            {{ errors[0] }}
          </template>
        </template>
      </HaFormField>
    </validation-provider>
    <p class="select-donation-payment-date__field__desc">
      {{ $t('campaign.field.paymentDate.nextPayment') }}
      <strong> {{ nextLevyDate }}. </strong>
    </p>
  </div>
</template>

<style lang="scss">
.select-donation-payment-date {
  text-align: left;
  border-radius: $ha-radius-large;

  &__field {
    span:not(.HaError) {
      color: var(--ha-color-text-light);
    }

    .HaFormField--Field,
    .Input--Wrapper,
    input {
      display: inline;
      max-width: calc($ha-unit * 5);
    }

    .Input--Wrapper .Input {
      min-height: $ha-unit;
      margin: 0 $ha-unit * 0.5;
      padding: calc($ha-unit * 0.5) $ha-unit;
    }

    &__desc {
      margin: $ha-spacing-medium 0 0 0;

      strong {
        color: var(--ha-color-primary);
      }
    }
  }
}
</style>
