<template>
  <validation-provider
    v-slot="{ errors }"
    tag="div"
    class="QuantitySelector"
    :rules="max ? `min_value:0|max_value:${max}` : `min_value:0`"
    mode="lazy"
  >
    <ha-button
      :variant="isQuickWinForm ? 'fill' : 'outline'"
      data-test="button-less"
      :disabled="!currentValue"
      :data-ux="`Forms_${metadata.type}_QuantitySelector_Add`"
      @click="currentValue = currentValue - 1"
    >
      -
      <span class="visually-hidden">
        {{ $t('button.remove') }}
      </span>
    </ha-button>
    <ha-input
      :id="`quantity-selector-id_${id}`"
      v-model.number="currentValue"
      data-test="input-quantity"
      type="text"
      :class="{ error: (max && currentValue > max) || currentValue < 0 }"
      :name="name ? name : 'quantity_' + id"
      :max-length="maxLength"
      :min="0"
      :max="max"
      :disabled="disabled"
      :is-valid="!!errors"
      :aria-label="$t('tiers.selector.labelInput')"
      hide-counter
      @keydown="checkEntry"
    />
    <ha-button
      :variant="isQuickWinForm ? 'fill' : 'outline'"
      data-test="button-plus"
      :loading="isLoading"
      :disabled="disableAddButton"
      :data-ux="`Forms_${metadata.type}_QuantitySelector_Remove`"
      @click="addQuantity"
    >
      +
      <span class="visually-hidden">
        {{ $t('button.add') }}
      </span>
    </ha-button>
    <div class="visually-hidden" role="status" aria-live="polite">
      {{ currentValue }}
    </div>
  </validation-provider>
</template>

<script>
import { toNumber } from 'lodash-es'
import { HaButton, HaInput } from '@ha/components'
import { ValidationProvider } from 'vee-validate'
import { enum as enums } from '@ha/helpers'
import useFormType from '@/composables/useFormType'

export default {
  name: 'QuantitySelector',
  components: {
    HaButton,
    HaInput,
    ValidationProvider
  },
  props: {
    id: {
      type: [String, Number],
      required: false
    },
    value: {
      type: [Number, String]
    },
    max: {
      type: Number,
      required: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: 2
    },
    name: String,
    disabled: Boolean
  },
  data() {
    return {
      currentValue: toNumber(this.value),
      isLoading: true
    }
  },
  setup() {
    const { isQuickWinForm } = useFormType()

    return {
      isQuickWinForm
    }
  },
  computed: {
    disableAddButton() {
      return this.isLoading || this.disabled || this.currentValue >= this.max
    },
    organizationName() {
      return this.organization?.name
    },
    organizationSlug() {
      return this.metadata.organization
    },
    formTypes() {
      return enums.FormType
    }
  },
  watch: {
    value() {
      this.currentValue = toNumber(this.value)
    },
    currentValue(newValue) {
      this.$emit('change', toNumber(newValue))
    }
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    addQuantity() {
      this.currentValue += 1
    },
    checkEntry(event) {
      // prevent typing . or - or not number and flooding with number
      if (
        (isNaN(event.key) &&
          !['Tab', 'Backspace', 'Delete', 'ArrowDown', 'ArrowUp'].includes(event.key)) ||
        (event.key === 'ArrowDown' && toNumber(event.target.value) === 0)
      ) {
        event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss">
.QuantitySelector {
  display: inline-flex;
  gap: 0.4em;

  @include mediaQuery(900) {
    justify-content: space-between;
    width: 100%;
  }

  .HaButton {
    width: $ha-unit * 5.25;
    padding: 0;
    font-size: $ha-font-size-big;

    &:first-of-type {
      font-size: 1.5em;
    }
  }

  .Input--Wrapper {
    max-width: $ha-unit * 6.5;
    margin: 0 $ha-spacing-mini;

    &.error .Input {
      border: $ha-border-width-regular solid var(--ha-color-danger);
    }

    .Input {
      min-width: $ha-unit * 5; // 40px
      text-align: center !important;

      &.Input--Valid {
        padding: 0 $ha-spacing-medium !important;
        background-image: none !important;
      }
    }
  }
}
</style>
