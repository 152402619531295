<script setup>
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { HaButton } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'
import useModalities from '@/composables/useModalities'

const props = defineProps({
  horizontal: { type: Boolean },
  centerText: { type: Boolean }
})
const { i18n } = useContext()
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods
const { organization } = useStoreData()
const { isPaymentTermsEnabled } = useModalities()


const wrapperClass = computed(() => [
  'ContributionNotice',
  {
    'ContributionNotice-Flex': props.horizontal,
    'ContributionNotice-CenterText': props.centerText
  }
])

const trackFirstClick = () => {
  onFirstClick('First Interaction Campaign', {
    step: 0,
    step_name: 'CGU'
  })
}

const text_1 = computed(() => i18n.t('abTasty.contributionNotice'))

</script>

<template>
  <div :class="wrapperClass">
    <img src="https://stockagehelloassoprod.blob.core.windows.net/images/forms/abtest/abcd_step1.svg"/>
    <p>
      <span v-if="isPaymentTermsEnabled">*</span><span v-html="text_1"></span>
      <HaButton
        :href="`/associations/${organization.organizationSlug}`"
        target="_blank"
        rel="noopener noreferrer"
        variant="link"
        :data-ux="`Forms_${metadata.type}_ContributionNotice_OrganismPage`"
        data-testid="link-asso-name"
        @click="trackFirstClick"
      >
        {{ organization.name }}
      </HaButton>.
    </p>
  </div>
</template>

<style lang="scss" scoped>

.ContributionNotice {
  p {
    margin-bottom: 0;
    margin-left: $ha-spacing-medium;
    color: var(--ha-color-text);
    font-size: $ha-font-size-small;
  }

  &-CenterText {
    display: flex;
    align-items: center;
  }

  &-Flex {
    @include mediaQuery(600) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;

      .ContributionNotice--Icon {
        margin: 0;
      }

      p {
        margin-left: $ha-spacing-medium;
      }
    }
  }
}
</style>
