<template>
    <div
      :class="{ Card: !isDonation, DonationHeader: isDonation }"
      class="CampaignHeader"
      data-testid="campaign-header"
    >
      <h1 class="CampaignHeader--Title" data-testid="title">
        <slot name="title">{{ title }}</slot>
      </h1>
      <ClientOnly v-if="isQuickWinForm && step === 1">
        <ShareButton />
      </ClientOnly>
      <h2 class="CampaignHeader--Subtitle">
        <slot name="subtitle">
          <template v-if="organizationName">
            {{ byWording }}
            <ha-button
              :href="`/associations/${metadata.organization}`"
              target="_blank"
              rel="noopener noreferrer"
              variant="link"
              :data-ux="`Forms_${metadata.type}_CampaignHeader_OrganismPage`"
            >
              {{ organizationName }}
            </ha-button>
          </template>
        </slot>
      </h2>
      <span v-if="dateLabel" :class="['CampaignHeader--Date',{ 'CampaignHeader--Date--quickWin': isQuickWinForm }]">
        <slot name="date">
          <fa icon="calendar-alt" />
          {{ dateLabel }}
        </slot>
      </span>
      <client-only>
        <section v-if="step === 1 && $device.isMobile && !isDonation && !isQuickWinForm" class="CampaignHeader--Share">
          <ShareLinks v-if="!isNavigatorShare" with-url-copy />
          <button
            v-if="isNavigatorShare"
            class="CampaignHeader--ShareButton"
            :data-ux="`Forms_${metadata.type}_CampaignHeader_ShareMobile`"
            @click="navigatorShare"
          >
            <fa icon="share-alt" />
          </button>
        </section>
      </client-only>
      <div v-if="description && !form.invertDescriptions">
        <p ref="description" :class="['CampaignHeader--Description', { 'ClipDescription': !fullDescription }]">
        {{ description }}
        </p>
        <ha-button class="ToggleButton" v-if="longDescription" variant="link" @click="toggleDescription"><span>{{ toggleButtonLabel }} <fa :icon="toggleIcon" /></span></ha-button>

    </div>
    </div>
</template>

<script>
import { get } from 'lodash-es'
import { HaButton } from '@ha/components'
import { getDateLabel } from '@/helpers/formUtils'
import { enum as enums, haMoment } from '@ha/helpers'

import ShareLinks from '@/components/globals/share/ShareLinks.vue'
import ShareButton from '@/components/forms/informations/partials/ShareButton.vue'
import useFormType from '@/composables/useFormType'

export default {
  name: 'CampaignHeader',
  components: {
    HaButton,
    ShareLinks,
    ShareButton
  },
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  setup() {
    const { isQuickWinForm } = useFormType()
    return {
      isQuickWinForm
    }
  },
  data() {
    return {
        longDescription: false,
        fullDescription: false
    }
  },
  mounted() {
    this.longDescription = this.$refs.description.scrollHeight > this.$refs.description.clientHeight
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    organizationName() {
      return get(
        this.$store.getters['organizations/getOrganization'](this.metadata.organization),
        'name'
      )
    },
    title() {
      return get(this, 'form.title')
    },
    description() {
      return get(this, 'form.description', '')
    },
    startDate() {
      return get(this, 'form.startDate')
    },
    endDate() {
      return get(this, 'form.endDate')
    },
    formTypes() {
      return enums.FormType
    },
    isDonation() {
      return this.formTypes.DONATION === this.form.formType
    },
    dateLabel() {
      // get the right date label dependings of the type of membership
      if ([this.formTypes.MEMBERSHIP].includes(this.form.formType)) {
        let label = ''
        switch (get(this.form, 'validityType')) {
          case 'MovingYear':
            label = this.$t('campaign.date.with2Dates.withNoTime', [
              haMoment(this.startDate).format('DD MMMM YYYY'),
              haMoment(this.startDate).add(1, 'year').format('DD MMMM YYYY')
            ])
            break
          case 'Illimited':
            return ''
          case 'Custom':
            label = this.$t('campaign.date.with2Dates.withNoTime', [
              haMoment(this.startDate).format('DD MMMM YYYY'),
              haMoment(this.endDate).format('DD MMMM YYYY')
            ])
            break
          default:
            break
        }
        return `${this.$t('campaign.membershipFrom')} ${label.toLowerCase()}`
      }
      return getDateLabel(this.startDate, this.endDate, this.$i18n)
    },
    isNavigatorShare() {
      return process.client && navigator.share
    },
    byWording() {
      return this.isDonation ? this.$t('campaignHeader.inFavour') : this.$t('campaignHeader.by')
    },
    toggleButtonLabel() {
        return this.fullDescription ? this.$t('abTasty.toggleLess') : this.$t('abTasty.toggleMore')
    },
    toggleIcon(){
        return this.fullDescription ? 'chevron-up' : 'chevron-down'
    }
  },
  methods: {
    navigatorShare() {
      if (navigator.share) {
        navigator.share({
          title: this.title,
          text: this.description,
          url: window.location.href
        })
      }
    },
    toggleDescription() {
        this.fullDescription = !this.fullDescription
    }
  }
}
</script>

<style lang="scss">
.CampaignHeader {
  &--Title {
    margin-bottom: $ha-spacing-mini;
    padding-right: $ha-spacing-big;
    font-size: $ha-font-size-jumbo;
    line-height: $ha-line-height-small;
  }

  &--Subtitle {
    margin: 0;
    color: var(--ha-color-text-light);
    font-weight: $ha-font-weight-regular;
    font-size: $ha-font-size-big;

    > .HaButton {
      font-size: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--Date {
    display: block;
    margin-top: $ha-spacing-small;
    margin-bottom: $ha-spacing-mini;
    color: var(--ha-color-text-lightest);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;

    &--quickWin {
      color: #505870;
    }

    svg {
      margin-right: $ha-spacing-mini;
    }
  }

  &--Description {
    margin-top: $ha-spacing-large;
    margin-bottom: 0;
    padding-top: $ha-spacing-medium;
    font-size: $ha-font-size-regular;
    white-space: pre-line;
    // word-break: break-word;
    border-top: $ha-border-width-regular solid set-alpha(border, 0.5);
  }

  .ClipDescription {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .ToggleButton {
    margin-top: $ha-spacing-tiny;
  }

  &--ShareButton {
    position: absolute;
    top: $ha-unit * 1.5; // 24px
    right: $ha-unit * 4.5; // 36px
    //width: $ha-unit * 4;
    //height: $ha-unit * 4;
    padding: $ha-spacing-small;
    color: var(--ha-color-text-light);
    outline: none;
  }

  .ShareLinks {
    margin-top: $ha-spacing-mini;
  }
}
</style>
