<script setup>
import { inject } from 'vue'
import { HaModal, HaButton } from '@ha/components'
import useRouteParams from '@/composables/useRouteParams'

const emit = defineEmits(['close'])
const isWidget = inject('isWidget')
const { formType } = useRouteParams()
</script>

<template>
  <HaModal class="modal-voluntary-contribution-explication" @close="emit('close')">
    <template #header>
      {{ $t('modal.contributionExplication.title') }}
    </template>
    <template #body>
      <p>
        {{ $t('modal.contributionExplication.intro_1') }}<br />
        {{ $t('modal.contributionExplication.intro_2') }}
      </p>
      <ul>
        <li>{{ $t('modal.contributionExplication.content_1') }}</li>
        <li>{{ $t('modal.contributionExplication.content_2') }}</li>
        <li>{{ $t('modal.contributionExplication.content_3') }}</li>
      </ul>
      <p>
        <strong>{{ $t('modal.contributionExplication.conclusion') }}</strong>
      </p>
    </template>
    <template #footer>
      <HaButton
        :data-ux="`Forms_${formType.toUpperCase()}${
          isWidget ? 'Widget' : ''
        }_Modal_ContributionExplication_GotIt`"
        @click="emit('close')"
      >
        {{ $t('modal.contributionExplication.cta') }}
      </HaButton>
    </template>
  </HaModal>
</template>

<style lang="scss">
.modal-voluntary-contribution-explication {
  font-size: $ha-font-size-small;

  ul {
    padding-left: $ha-spacing-big;
    list-style: disc;

    li {
      margin-bottom: $ha-spacing-medium;
      color: var(--ha-color-text-light);
    }

    + p {
      color: var(--ha-color-text);
    }
  }
}
</style>
