var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"button-list"},[_vm._l((_setup.buttonStates),function(optionState,index){return _c(_setup.HaButton,{key:index,class:optionState.class,attrs:{"variant":optionState.variant},on:{"mousedown":_setup.onMousedown,"click":function($event){return _setup.onClick(optionState.tipValue)}}},[_vm._v("\n    "+_vm._s(optionState.tipValue)+"\n  ")])}),_vm._v(" "),_c('div',{staticClass:"button-list__item button-list__custom-field"},[_c(_setup.HaInput,{class:[
        'button-list__custom-field__input',
        {
          'button-list__custom-field__input--selected':
            !_setup.isCustomFieldFocused && _setup.isUsingCustomField
        }
      ],attrs:{"value":_setup.isUsingCustomField ? _vm.tip : '',"placeholder":_setup.functions.capitalizeFirstLetter(_vm.$t('globals.other')),"data-ux":_setup.dataUxInput,"size":"small","step":"0.01"},on:{"focus":_setup.onCustomFieldFocus,"blur":_setup.onCustomFieldBlur,"input":_setup.onCustomValueInput,"keydown":function($event){return _setup.functions.allowOnlyNumericEntry($event, { allowDecimal: true })}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }