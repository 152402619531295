<template>
 <div class="range-slider" @mousedown="startDrag">
    <input ref="slider" type="range" class="slider" :min="min" :max="max" :value="rangeValue" :step="step" @input="updateSlider($event)">
    <div ref="sliderThumb" class="slider-thumb">
      <svg viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(4, 10)">
        <path d="M0.499757 7.00012L9.87567 1.58694L9.87567 12.4133L0.499757 7.00012Z"/>
      </g>
      <g transform="translate(17, 10)">
        <path d="M12.501 7.00012L3.12507 12.4133L3.12507 1.58693L12.501 7.00012Z"/>
      </g>
      </svg>
      <div ref="sliderTooltip" class="tooltip"></div>
    </div>
    <div ref="sliderProgress" class="progress"></div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  min: { type: Number, default: 0 },
  max: { type: [Number, String], default: 100 },
  step: { type: [Number, String], default: 1 },
  rangeValue: { type: [Number, String], default: 50 }
})

const emit = defineEmits(['input'])

const slider = ref(null)
const sliderThumb = ref(null)
const sliderTooltip = ref(null)
const sliderProgress = ref(null)
const isDragging = ref(false)

onMounted(() => {
  updateRange(props.rangeValue)
})

const updateRange = value => {
  const maxVal = Number(slider.value.getAttribute('max'))
  const valueToNumber = Number(value)
  const val = (valueToNumber / maxVal) * 100 + '%'
  sliderTooltip.value.innerHTML = parseValue(valueToNumber) + ' €'
  if (valueToNumber > maxVal) {
    sliderProgress.value.style.width = '100%'
    sliderThumb.value.style.left = '100%'
  } else {
    sliderProgress.value.style.width = val
    sliderThumb.value.style.left = val
  }
}

const updateSlider = (e) => {
  emit('input', parseValue(e.target.value))
  updateRange(slider.value.value)
}

const startDrag = () => {
  isDragging.value = true
  document.addEventListener('mousemove', handleDrag)
  document.addEventListener('mouseup', stopDrag)
}

const handleDrag = (e) => {
  if (!isDragging.value) return
  const sliderRect = slider.value.getBoundingClientRect()
  const maxVal = parseFloat(slider.value.max)
  const minVal = parseFloat(slider.value.min)
  const stepVal = parseFloat(slider.value.step)
  let newValue = Math.round(((e.clientX - sliderRect.left) / sliderRect.width) * (maxVal - minVal) / stepVal) * stepVal + minVal
  newValue = Math.max(minVal, Math.min(maxVal, newValue)).toFixed(2)
  slider.value.value = newValue
  emit('input', parseValue(e.target.value))
  updateRange(newValue)
}

const stopDrag = () => {
  isDragging.value = false
  document.removeEventListener('mousemove', handleDrag)
  document.removeEventListener('mouseup', stopDrag)
}

const parseValue = (value) => value === "" ? 0 : parseFloat(value).toFixed(2)

onBeforeUnmount(() => {
  document.removeEventListener('mousemove', handleDrag)
  document.removeEventListener('mouseup', stopDrag)
})

// TODO : find a way to not trigger watch when range input event update rangeValue
watch(props, ({ rangeValue }) => {
    updateRange(parseValue(rangeValue))
  }
)
</script>

<style scoped lang="scss">
/* stylelint-disable */
.range-slider {
  position: relative;
  width: 100%;

  .slider {
    position: relative;
    display: flex;
    width: 100%;
    height: 20px;
    background: color-mix(in srgb, var(--ha-color-flint) 20%, transparent);
    border: 1px solid var(--ha-color-flint);
    border-radius: 50px;
    outline: none;
    appearance: none;

    &::-webkit-slider-thumb {
      position: relative;
      width: 45px;
      height: 45px;
      cursor: grab;
      appearance: none;

      &:active {
        cursor: grabbing;
      }
    }

    &::-moz-range-thumb {
      position: relative;
      display: none;
      width: 45px;
      height: 45px;
      appearance: none;
      background-color: transparent;
      border-radius: 50px;
      border: none;
      outline: none;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

  }

  .slider-thumb {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    background: var(--ha-color-midnight);
    border: 5px solid var(--ha-color-white);
    border-radius: 50px;
    pointer-events: none;
    outline: color-mix(in srgb, var(--ha-color-flint) 30%, transparent) 1px solid;

    svg {
      fill: white;
    }
  }

  &:hover .slider-thumb {
    background: color-mix(in srgb, var(--ha-color-midnight) 80%, var(--ha-color-white));

    .tooltip {
      display: flex;
    }
  }

  .tooltip {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    width: content;
    padding: 1rem .5rem;
    height: 2em;
    top: -2em;
    left: 50%;
    color: #fff;
    background: var(--ha-color-midnight);
    transform: translate(-50%, -50%);
    border-radius: 3px;
    white-space: nowrap;
    font-size: $ha-font-size-small;

    &::before {
      position: absolute;
      content: '';
      bottom: -0.5em;
      left: 50%;
      width: 0;
      height: 0;
      border-top: 10px solid var(--ha-color-midnight);
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      transform: translate(-50%);
    }
  }
  .progress {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    height: 20px;
    background: var(--ha-color-iris);
    border-radius: 50px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

</style>
