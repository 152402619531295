<template>
  <button
    type="button"
    :class="[
      'NavStep',
      {
        'NavStep-Current': isCurrent,
        'NavStep-Completed': isCompleted,
        'NavStep-a11y': isQuickWinForm
      }
    ]"
    :disabled="isDisabled"
    :tabindex="isCurrent || isDisabled ? -1 : 0"
    :style="getStyles"
  >
    <fa :icon="icon" aria-hidden="true" />
    <span v-if="isCompleted || isCurrent" class="visually-hidden">
      {{ isCurrent ? 'Actuelle :' : isCompleted ? 'Complétée :' : '' }}
    </span>
    <span>{{ title }}</span>
  </button>
</template>

<script>
import useFormType from '@/composables/useFormType'

export default {
  name: 'NavStep',
  props: {
    icon: String,
    title: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    isCurrent: {
      type: Boolean,
      default: false,
      required: false
    },
    isCompleted: {
      type: Boolean,
      default: false,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup() {
    const { isQuickWinForm } = useFormType()

    return {
      isQuickWinForm
    }
  },
  computed: {
    getStyles() {
      return {
        width: `${this.width}%`
      }
    }
  }
}
</script>

<style lang="scss">
.NavStep {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: $ha-spacing-medium;
  color: #505870;
  font-weight: $ha-font-weight-semibold;
  font-size: $ha-unit * 1.625; // 13px
  text-align: center;
  background: #d1d6de;

  @include mediaQuery(600) {
    flex-direction: column;
  }

  @include mediaQuery(900) {
    padding-right: 0;

    &:not(:last-child)::before {
      right: -($ha-unit * 2.375); // -19px
      margin-top: -($ha-unit * 8.75); // -70px
      border-color: set-alpha('black', 0) set-alpha('black', 0) set-alpha('black', 0)
        var(--ha-color-white);
      border-width: ($ha-unit * 8.75) 0 ($ha-unit * 8.75) ($ha-unit * 3.125); // 70px 0 70px 25px
    }

    &:not(:last-child)::after {
      right: -($ha-unit * 2.125); // -17px
      margin-top: -($ha-unit * 9); // -72px
      border-color: set-alpha('black', 0) set-alpha('black', 0) set-alpha('black', 0) #d1d6de;
      border-width: ($ha-unit * 9) 0 ($ha-unit * 9) ($ha-unit * 3.375); // 72px 0 72px 27px
    }

    &:not(:last-child)::before,
    &:not(:last-child)::after {
      position: absolute;
      top: 50%;
      z-index: 1;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }
  }

  @include mediaQuery(1200) {
    flex-direction: row;
    padding-top: $ha-spacing-large;
    padding-right: 0;
    padding-bottom: $ha-spacing-large;
    font-size: $ha-font-size-small;
    text-align: left;

    &:first-child,
    &:last-child {
      padding-left: $ha-spacing-medium;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &-a11y {
    color: var(--ha-color-text-light);
  }

  + .NavStep {
    border-left: $ha-border-width-regular solid set-alpha('white', 0.25);

    @include mediaQuery(900) {
      border: none;
    }
  }

  &-Completed,
  &-Current {
    color: var(--ha-color-white);
    background-color: var(--ha-color-primary);
    cursor: pointer;

    &:focus {
      background-color: set-lightness(primary, 0.9);

      &::after {
        border-color: set-alpha('black', 0) set-alpha('black', 0) set-alpha('black', 0)
          set-lightness(primary, 0.9) !important;
      }

      &:last-of-type {
        &::before {
          border-color: set-alpha('black', 0) set-alpha('black', 0) set-alpha('black', 0)
            set-lightness(primary, 0.9);
        }
      }
    }

    &::after {
      border-color: set-alpha('black', 0) set-alpha('black', 0) set-alpha('black', 0)
        var(--ha-color-primary) !important;
    }

    &:last-of-type {
      &::before {
        border-color: set-alpha('black', 0) set-alpha('black', 0) set-alpha('black', 0)
          var(--ha-color-primary);
      }
    }
  }

  &-Current {
    pointer-events: none;
  }

  span {
    display: none;

    @include mediaQuery(600) {
      display: block;
    }
  }

  svg {
    @include mediaQuery(600) {
      margin-top: $ha-spacing-mini;
      margin-bottom: $ha-spacing-mini;
    }

    @include mediaQuery(1200) {
      margin: 0;
      margin-right: $ha-spacing-small;
    }
  }
}
</style>
